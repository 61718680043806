/**
 * 系统配置
 */
 import req from './index.js'


//分厂厂长
 export const indicatorData = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/IndicatorData',param) };//指标数据
 export const indicatorDetailList = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/IndicatorDetailList',param) };//指标详细清单
 export const kpiStatisticDimensionalitySelector = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/KpiStatisticDimensionalitySelector',param) };//KPI统计-维度下拉选
 export const kpiStatisticEntitySelector = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/KpiStatisticEntitySelector',param) };//KPI统计-实体下拉选+
 export const kpiStatistic = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/KpiStatistic',param) };//KPI统计
 export const productionInfo = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/ProductionInfo',param) };//生产数据仪表盘、折线图
 export const kpiAnalysisIndicatorSelector = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/KpiAnalysisIndicatorSelector',param) };//Kpi分析指标下拉选
 export const kpiAnalysis = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/KpiAnalysis',param) };//Kpi分析
 export const productionAnalysisDataSelector = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/ProductionAnalysisDataSelector',param) };//生产数据分析下拉框
 export const productionAnalysis = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/ProductionAnalysis',param) };//生产数据分析

// 组织架构选择
export const kpiStatisticOrganizationSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/KpiStatisticOrganizationSelect',param) };


// 生产指标--生产信息
export const productionWaitDoneCount = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/WaitDoneCount',param) };//待办数量
export const productionDoneExecutorSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/WaitDoneExecutorSelect',param) };//待办执行人选择框
export const productionWaitDoneWorkOrderList = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/WaitDoneWorkOrderList',param) };//待办工单清单

export const productionPointInspectioExecutorSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/PointInspectioExecutorSelect',param) };//点检执行人选择框
export const productionPointWorkOrderExecutorSelect= param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/PointInspectioWorkOrderExecutorSelect',param) };//点检工单状态选择框
export const productionPointInspectioWorkOrderList = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/PointInspectioWorkOrderList',param) };//点检工单清单
 

export const productionMaintainExecutorSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/MaintainExecutorSelect',param) };//保养执行人选择框
export const productionMaintainWorkOrderExecutorSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/MaintainWorkOrderExecutorSelect',param) };//保养工单状态选择框
export const productionMaintainWorkOrderList = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/MaintainWorkOrderList',param) };//保养工单清单

export const productionRepairExecutorSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/RepairExecutorSelect',param) };//维修执行人选择框
export const productionRepairWorkOrderExecutorSelect = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/RepairWorkOrderExecutorSelect',param) };//维修工单状态选择框
export const productionRepairWorkOrderList = param => { return req.post('EMS.Thing.Api.ProductionIndicator/Services/RepairWorkOrderList',param) };//维修工单清单



 
//  维修指标--开始
export const repairIndicatorData = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/IndicatorData',param) };//指标数据
export const repairIndicatorDetailList = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/IndicatorDetailList',param) };//指标详细清单
export const repairWaitDoneCount = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/WaitDoneCount',param) };//待办数量
export const repairWaitDoneExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/WaitDoneExecutorSelect',param) };//待办执行人选择框
export const repairWaitDoneWorkOrderList = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/WaitDoneWorkOrderList',param) };//待办工单清单
export const repairWaitDoneTableSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/WaitDoneTableSelect',param) };//待办表类型下拉框


export const repairPointInspectioExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/PointInspectioExecutorSelect',param) };//点检执行人选择框
export const repairPointInspectioWorkOrderExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/PointInspectioWorkOrderExecutorSelect',param) };//点检工单状态选择框
export const repairPointInspectioWorkOrderList = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/PointInspectioWorkOrderList',param) };//点检工单清单

export const repairMaintainExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/MaintainExecutorSelect',param) };//保养执行人选择框
export const repairMaintainWorkOrderExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/MaintainWorkOrderExecutorSelect',param) };//保养工单状态选择框
export const repairMaintainWorkOrderList = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/MaintainWorkOrderList',param) };//保养工单清单

export const repairRepairExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/RepairExecutorSelect',param) };//维修执行人选择框
export const repairRepairWorkOrderExecutorSelect = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/RepairWorkOrderExecutorSelect',param) };//维修工单状态选择框
export const repairRepairWorkOrderList = param => { return req.post('EMS.Thing.Api.RepairIndicator/Services/RepairWorkOrderList',param) };//维修工单清单
//  维修指标--结束