<!-- 分厂厂长 -->
<template>
    <div class="page_container " v-loading="loading">
        <el-row :gutter="12">
            <!-- 模块1 -->
            <el-col :span="15" class="diy_col">
                <div class="bg-purple">生产指标</div>
                <el-row class="bgc_FFFFFF">
                    <!-- 模块1.1 -->
                    <el-col :span="12" class="height_vh border_bottom_1_CCDBE5 border_right_1_CCDBE5">
                        <div class="color_000000 fn_size18 flex justify_between align_center"
                            style="margin: 16px 18px 10px;">
                            <div>统计数据</div>
                            <div class="flex">
                                <el-select v-model="branchFactorySelect" placeholder="选择时间维度"
                                    @change="changeBranchFactorySelect" class="width_90">
                                    <el-option v-for="item in  dataFrame1" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-date-picker v-model="statisticalDataTime" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10 datePickerWidth"
                                    @change="changeBranchFactoryDateTime" :picker-options="pickerOptions">
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="fn_size14 color_808080">
                            <el-row>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="equipmentDetailDialog">
                                        <div class="">设备总数(台)</div>
                                        <div class="color_040A45 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{equipmentTotal.equipmentNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="errDetailDialog">
                                        <div class="">连接异常(台)</div>
                                        <div class="color_FE4657 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{errTotal.errNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="failureReportDetailDialog">
                                        <div class="">本月报修(单)</div>
                                        <div class="color_03C378 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{failureReportTotal.failureReportNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="lowOEEDetailDialog">
                                        <div class="">OEE低设备(台)</div>
                                        <div class="color_4D84F4 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{lowOEETotal.lowOEENum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="lowOperationRateNumDetailDialog">
                                        <div class="">开机率低(台)</div>
                                        <div class="color_4D84F4 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{lowOperationRateTotal.lowOperationRateNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="lowUseRatioDetailDialog">
                                        <div class="">开机利用率低(台)</div>
                                        <div class="color_4D84F4 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{lowUseRatioTotal.lowUseRatioNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="agentPointNum_dialogTableVisible = true;">
                                        <div class="">点检待办单(单)</div>
                                        <div class="color_FF9014 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{agentPointTotal.agentPointNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="errPointNum_dialogTableVisible = true">
                                        <div class="">异常单(单)</div>
                                        <div class="color_FE4657 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{errPointTotal.errPointNum}}</div>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="mg_top_17">
                                    <div @click="overdueNum_dialogTableVisible = true">
                                        <div class="">逾期单(单)</div>
                                        <div class="color_FF9014 height_30" style="font-size: 26px;font-weight: bold;">
                                            {{overdueNum}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>

                    <!-- 模块1.2 -->
                    <el-col :span="12" class="height_vh border_bottom_1_CCDBE5">
                        <div class="color_000000 fn_size18 flex justify_between align_center"
                            style="margin: 16px 18px 10px;">
                            <div>设备KPI统计</div>
                            <div>
                                <!--                                 <el-cascader class="mg_right_10 width_190" ref="demoCascader" placeholder="组织架构选择"
                                    v-model="organizationValue" :options="TreeList" :props="defaultProps"
                                    @change="changeOrganization" :show-all-levels=false collapse-tags clearable>
                                </el-cascader> -->
                                <el-cascader class="mg_right_10 " ref="demoCascader" placeholder="选择设备"
                                    v-model="organizationValue" :options="organizationAr" :props="defaultProps"
                                    @change="changeOrganization" :show-all-levels=false collapse-tags clearable>
                                </el-cascader>
                                <!-- <el-select v-model="value" placeholder="选择数据维度" class="mg_left_10">
                                    <el-option v-for="item in options" :key="item.value" :label="item.name"
                                        :value="item.value">
                                    </el-option>
                                </el-select> -->
                            </div>
                        </div>
                        <div class="flex justify_between " style="height: 75%;width: 100%;">
                            <div id="equiKPIStatisticsOEE" style="width: 100%; height: 100%;"></div>
                            <!-- 弹框 -->
                            <el-popover ref="popover" placement="bottom" trigger="hover" v-model="visible"
                                visible-arrow="false" width="410" popper-class="my-popover">
                                <div class="fn_size18 color_1A1A1A text_align_center" style="font-weight: bold;">利用率 50%
                                </div>
                                <div class="flex justify_between mg_left_10 mg_right_10 mg_top_10">
                                    <div class="diy_div">
                                        <div id="equiKPIStatisticsUtilizationRatePopover1"
                                            style="width: 100%; height: 100%;"></div>
                                    </div>
                                    <div class="diy_div">
                                        <div id="equiKPIStatisticsUtilizationRatePopover2"
                                            style="width: 100%; height: 100%;"></div>
                                    </div>
                                    <div class="diy_div">
                                        <div id="equiKPIStatisticsUtilizationRatePopover3"
                                            style="width: 100%; height: 100%;"></div>
                                    </div>
                                </div>
                            </el-popover>
                            <div id="equiKPIStatisticsUtilizationRate" style="width: 100%; height: 100%;"></div>
                            <div id="equiKPIStatisticsPowerOnRate" style="width: 100%; height: 100%;"></div>
                        </div>
                    </el-col>

                    <!-- 模块1.3 -->
                    <el-col :span="12" class="height_vh1 border_right_1_CCDBE5">
                        <div class="flex flex_column" style="margin: 16px 18px 0px 18px;">
                            <div class=" fn_size18">
                                <div class="color_FF9014 pull-left">
                                    前一天计划<span style="font-size: 30px;">{{yesterdayOutput.PlanOutput}}</span>件
                                </div>
                                <div class="color_03C378 pull-right">
                                    前一天完成<span style="font-size: 30px;">{{yesterdayOutput.ActualOutput}}</span>件
                                </div>
                            </div>
                        </div>
                        <div id="productionCompletionRate1" style="width: 100%; height: 80%;"></div>
                    </el-col>

                    <!-- 模块1.4 -->
                    <el-col :span="12" class="height_vh1">
                        <div class="flex flex_column" style="margin: 16px 18px 0px 18px;">
                            <div class=" fn_size18">
                                <div class="color_FF9014 pull-left">
                                    当天计划<span style="font-size: 30px;">{{todayOutput.PlanOutput}}</span>件
                                </div>
                                <div class="color_03C378 pull-right">
                                    当天完成<span style="font-size: 30px;">{{todayOutput.ActualOutput}}</span>件
                                </div>
                            </div>
                        </div>
                        <div id="productionCompletionRate2" style="width: 100%; height: 80%;"></div>
                    </el-col>
                </el-row>
            </el-col>


            <el-col :span="9" class="diy_col bgc_FFFFFF">
                <div class=" bg-purple" style="margin-left: -6px;margin-right: -6px;">生产信息</div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane name="first">
                        <el-badge slot="label" :value="waitDoneCount.agentNum" class="badgeitem"><span>待办</span>
                        </el-badge>
                        <div class="flex align_center">
                            <div class="color_999999 fn_size16">待办总数：</div>
                            <div class="fn_size30">{{waitDoneCount.agentNum}}</div>
                            <div class="color_999999 fn_size16 mg_left_50">今日已办：</div>
                            <div class="fn_size30">{{waitDoneCount.doneNum}}</div>
                        </div>
                        <!-- 下拉框  -->
                        <div class="flex align_center ">
                            <!-- <span class="fn_size14">执行人：</span> -->
                            <el-select v-model="option_peopleValue" placeholder="请选择执行人" class="mg_right_10 width_170"
                                multiple collapse-tags>
                                <el-option v-for="(item,index) in option_people" :key="index" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <el-select v-model="waitDoneTableArValue" placeholder="请选择" class="mg_right_10 width_150">
                                <el-option v-for="item in waitDoneTableAr" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <el-date-picker v-model="waitDoneTime" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10">
                            </el-date-picker> -->
                            <el-button type="primary" size="mini" @click="queryWaitDone"><i class="select_icon"></i>
                            </el-button>
                        </div>
                        <!-- 折叠面板 -->
                        <div class="mg_top_10 collapseStyle">
                            <!-- 维修 -->
                            <el-table :data="waitDoneList" style="width: 100%" height="90%" v-if="currentTable==1">
                                <el-table-column type="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="orderID" label="工单编号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="deviceID" label="设备编号">
                                </el-table-column>
                                <el-table-column prop="deviceName" label="设备名称">
                                </el-table-column>
                                <el-table-column prop="deviceType" label="设备类型">
                                </el-table-column>
                                <el-table-column prop="deviceModel" label="设备型号">
                                </el-table-column>
                                <el-table-column prop="orderStatus" label="工单状态">
                                </el-table-column>
                                <el-table-column prop="deviceDepartment" label="设备所在部门">
                                </el-table-column>
                                <el-table-column prop="deviceLocation" label="设备所在地点">
                                </el-table-column>
                                <el-table-column prop="oprPerson" label="记录人">
                                </el-table-column>

                                <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execGroup" label="维修组">
                                </el-table-column>
                                <el-table-column prop="execPerson" label="维修人">
                                </el-table-column>
                                <el-table-column prop="execLevel" label="维修等级">
                                </el-table-column>
                                <el-table-column prop="execType" label="维修类型">
                                </el-table-column>
                                <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）">
                                </el-table-column>
                                <el-table-column prop="urgenLevel" label="紧急程度">
                                </el-table-column>
                                <el-table-column label="是否停机">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.machineStop}}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="stopLen" label="停机时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期接单">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isTakeOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期维修结束">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEndOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execLenM" label="维修时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="execFee" label="保养费用（元）">
                                </el-table-column>
                                <el-table-column prop="spotCondition" label="现场状况">
                                </el-table-column>

                                <el-table-column prop="execDesc" label="工作描述">
                                </el-table-column>
                                <el-table-column prop="execGrade" label="工单评分等级">
                                </el-table-column>
                                <el-table-column prop="isEmpirical" label="是否为经验性记录">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEmpirical}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="是否委外">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isOutEntrust}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="remarks" label="备注">
                                </el-table-column>
                                <el-table-column prop="execPersonNick" label="维修人员昵称名单">
                                </el-table-column>
                                <el-table-column prop="assignEndTime" label="指定维修结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="verifyRemarks" label="验证备注">
                                </el-table-column>
                                <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                                </el-table-column>
                            </el-table>
                            <!-- 保养 -->
                            <el-table :data="waitDoneList" style="width: 100%" height="90%" v-if="currentTable==2">
                                <el-table-column type="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="orderID" label="工单编号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="deviceID" label="设备编号">
                                </el-table-column>
                                <el-table-column prop="deviceName" label="设备名称">
                                </el-table-column>
                                <el-table-column prop="deviceType" label="设备类型">
                                </el-table-column>
                                <el-table-column prop="deviceModel" label="设备型号">
                                </el-table-column>
                                <el-table-column prop="orderStatus" label="工单状态">
                                </el-table-column>
                                <el-table-column prop="deviceDepartment" label="设备所在部门">
                                </el-table-column>
                                <el-table-column prop="deviceLocation" label="设备所在地点">
                                </el-table-column>
                                <el-table-column prop="oprPerson" label="记录人">
                                </el-table-column>

                                <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execGroup" label="保养组">
                                </el-table-column>
                                <el-table-column prop="execPerson" label="保养人">
                                </el-table-column>
                                <el-table-column prop="execLevel" label="保养等级">
                                </el-table-column>
                                <el-table-column prop="execType" label="保养类型">
                                </el-table-column>
                                <el-table-column prop="execEstimateLen" label="保养预估花费时间（分钟）">
                                </el-table-column>
                                <el-table-column prop="urgenLevel" label="紧急程度">
                                </el-table-column>
                                <el-table-column label="是否停机">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.machineStop}}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="stopLen" label="停机时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期接单">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isTakeOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="startTime" label="保养开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期保养结束">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEndOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectStartTime" label="验证不通过保养开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectEndTime" label="验证不通过保养结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execLenM" label="保养时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="execFee" label="保养费用（元）">
                                </el-table-column>
                                <el-table-column prop="spotCondition" label="现场状况">
                                </el-table-column>

                                <el-table-column prop="execDesc" label="工作描述">
                                </el-table-column>
                                <el-table-column prop="execGrade" label="工单评分等级">
                                </el-table-column>
                                <el-table-column prop="isEmpirical" label="是否为经验性记录">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEmpirical}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="是否委外">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isOutEntrust}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="remarks" label="备注">
                                </el-table-column>
                                <el-table-column prop="execPersonNick" label="保养人员昵称名单">
                                </el-table-column>
                                <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="verifyRemarks" label="验证备注">
                                </el-table-column>
                                <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                                </el-table-column>
                            </el-table>
                            <!-- 点检 -->
                            <el-table :data="waitDoneList" style="width: 100%" height="90%" v-if="currentTable==3">
                                <el-table-column type="index" label="序号"></el-table-column>
                                <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                                <el-table-column prop="orderType" label="点检类型"></el-table-column>
                                <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                                <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                                <el-table-column label="是否异常">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isAbnormal}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                                <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                                <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                                <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期点检结束">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEndOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                                <!-- <el-table-column prop="assignEndTime" label="指定点检结束时间" :formatter="formatDate">
                            </el-table-column>
                            <el-table-column prop="pause_time_list" label="暂离时间列表"></el-table-column>
                            <el-table-column prop="execGroup" label="点检班组"></el-table-column>
                            <el-table-column prop="execGroupPerson" label="点检班组成员"></el-table-column>
                            <el-table-column prop="execPerson" label="点检人"></el-table-column> -->
                                <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                                <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                                <el-table-column prop="remarks" label="备注"></el-table-column>
                                <el-table-column width="80" label="点检结果列表">
                                    <template slot-scope="props">
                                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                            <span style="border-bottom: 1px solid #009BFD;"
                                                @click="lookResult(props.row)">查看</span>
                                        </el-button>
                                    </template>
                                </el-table-column>
                                <!-- 点检结果列表   orderRecordList -->
                            </el-table>
                            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize"
                                :page-size="pageSize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChangeWaitDone">
                            </el-pagination>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane name="second">
                        <el-badge slot="label" class="badgeitem"><span>点检</span></el-badge>
                        <!-- 下拉框  -->
                        <div class="flex align_center mg_top_10">
                            <!-- <span class="fn_size14">执行人：</span> -->
                            <el-select v-model="djPeopleArValue" placeholder="请选择" class="mg_right_10 width_170"
                                multiple collapse-tags>
                                <el-option v-for="item in djPeopleAr" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <span class="fn_size14">工单状态：</span> -->
                            <el-select v-model="spotOrderStatusValue" placeholder="选择工单状态" class="mg_right_10 width_180"
                                multiple collapse-tags>
                                <el-option v-for="item in spotOrderStatus" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <el-date-picker v-model="djTime" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10">
                            </el-date-picker> -->
                            <el-button type="primary" size="mini" @click="queryWDJ"><i class="select_icon"></i>
                            </el-button>
                        </div>
                        <div class="mg_top_10 collapseStyle1">
                            <el-table :data="djList" style="width: 100%" height="90%">
                                <el-table-column type="index" label="序号"></el-table-column>
                                <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                                <el-table-column prop="orderType" label="点检类型"></el-table-column>
                                <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                                <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                                <el-table-column label="是否异常">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isAbnormal}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                                <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                                <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                                <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期点检结束">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEndOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                                <el-table-column prop="assignEndTime" label="指定点检结束时间" :formatter="formatDate">
                                </el-table-column>
                                <!-- <el-table-column prop="pause_time_list" label="暂离时间列表"></el-table-column> -->
                                <el-table-column prop="execGroup" label="点检班组"></el-table-column>
                                <el-table-column prop="execGroupPerson" label="点检班组成员"></el-table-column>
                                <el-table-column prop="execPerson" label="点检人"></el-table-column>
                                <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                                <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                                <el-table-column prop="remarks" label="备注"></el-table-column>
                                <el-table-column width="80" label="点检结果列表">
                                    <template slot-scope="props">
                                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                            <span style="border-bottom: 1px solid #009BFD;"
                                                @click="lookResult(props.row)">查看</span>
                                        </el-button>
                                    </template>
                                </el-table-column>
                                <!-- 点检结果列表   orderRecordList -->
                            </el-table>
                            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize"
                                :page-size="pageSize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChangeDJ"></el-pagination>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="third">
                        <el-badge slot="label" class="badgeitem"><span>保养</span></el-badge>
                        <!-- 下拉框  -->
                        <div class="flex align_center mg_top_10">
                            <!-- <span class="fn_size14">执行人：</span> -->
                            <el-select v-model="maintainPeopleValue" placeholder="请选择执行人" class="mg_right_10 width_170"
                                multiple collapse-tags>
                                <el-option v-for="(item,index) in maintainPeople" :key="index" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <span class="fn_size14">工单状态：</span> -->
                            <el-select v-model="maintainStatusValue" placeholder="选择工单状态" class="mg_right_10 width_180"
                                multiple collapse-tags>
                                <el-option v-for="item in maintainStatus" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <el-date-picker v-model="maintainTime" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10">
                            </el-date-picker> -->
                            <el-button type="primary" size="mini" @click="queryMaintain"><i class="select_icon"></i>
                            </el-button>
                        </div>

                        <div class="mg_top_10 collapseStyle1">
                            <el-table :data="maintainList" style="width: 100%" height="90%">
                                <el-table-column type="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="orderID" label="工单编号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="deviceID" label="设备编号">
                                </el-table-column>
                                <el-table-column prop="deviceName" label="设备名称">
                                </el-table-column>
                                <el-table-column prop="deviceType" label="设备类型">
                                </el-table-column>
                                <el-table-column prop="deviceModel" label="设备型号">
                                </el-table-column>
                                <el-table-column prop="orderStatus" label="工单状态">
                                </el-table-column>
                                <el-table-column prop="deviceDepartment" label="设备所在部门">
                                </el-table-column>
                                <el-table-column prop="deviceLocation" label="设备所在地点">
                                </el-table-column>
                                <el-table-column prop="oprPerson" label="记录人">
                                </el-table-column>

                                <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execGroup" label="保养组">
                                </el-table-column>
                                <el-table-column prop="execPerson" label="保养人">
                                </el-table-column>
                                <el-table-column prop="execLevel" label="保养等级">
                                </el-table-column>
                                <el-table-column prop="execType" label="保养类型">
                                </el-table-column>
                                <el-table-column prop="execEstimateLen" label="保养预估花费时间（分钟）">
                                </el-table-column>
                                <el-table-column prop="urgenLevel" label="紧急程度">
                                </el-table-column>
                                <el-table-column label="是否停机">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.machineStop}}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="stopLen" label="停机时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期接单">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isTakeOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="startTime" label="保养开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期保养结束">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEndOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectStartTime" label="验证不通过保养开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectEndTime" label="验证不通过保养结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execLenM" label="保养时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="execFee" label="保养费用（元）">
                                </el-table-column>
                                <el-table-column prop="spotCondition" label="现场状况">
                                </el-table-column>

                                <el-table-column prop="execDesc" label="工作描述">
                                </el-table-column>
                                <el-table-column prop="execGrade" label="工单评分等级">
                                </el-table-column>
                                <el-table-column prop="isEmpirical" label="是否为经验性记录">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEmpirical}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="是否委外">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isOutEntrust}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="remarks" label="备注">
                                </el-table-column>
                                <el-table-column prop="execPersonNick" label="保养人员昵称名单">
                                </el-table-column>
                                <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="verifyRemarks" label="验证备注">
                                </el-table-column>
                                <el-table-column prop="verifyTimeBak" label="验证时间（备用）" :formatter="formatDate">
                                </el-table-column>
                            </el-table>
                            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize"
                                :page-size="pageSize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChangeMaintain">
                            </el-pagination>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane name="fourth">
                        <el-badge slot="label" class="badgeitem"><span>维修</span></el-badge>
                        <!-- 下拉框  -->
                        <div class="flex align_center mg_top_10">
                            <!-- <span class="fn_size14">执行人：</span> -->
                            <el-select v-model="repairPeopleValue" placeholder="请选择执行人" class="mg_right_10 width_170"
                                multiple collapse-tags>
                                <el-option v-for="(item,index) in repairPeople" :key="index" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <span class="fn_size14">工单状态：</span> -->
                            <el-select v-model="repairStatusValue" placeholder="选择工单状态" class="mg_right_10 width_180"
                                multiple collapse-tags>
                                <el-option v-for="item in repairStatus" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                            <!-- <el-date-picker v-model="repairTime" type="datetimerange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" class="mg_right_10">
                            </el-date-picker> -->
                            <el-button type="primary" size="mini" @click="queryRepair"><i class="select_icon"></i>
                            </el-button>
                        </div>
                        <div class="mg_top_10 collapseStyle1">
                            <el-table :data="repairList" style="width: 100%" height="90%">
                                <el-table-column type="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="orderID" label="维修工单编号" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="deviceID" label="设备编号">
                                </el-table-column>
                                <el-table-column prop="deviceName" label="设备名称">
                                </el-table-column>
                                <el-table-column prop="deviceType" label="设备类型">
                                </el-table-column>
                                <el-table-column prop="deviceModel" label="设备型号">
                                </el-table-column>
                                <!-- <el-table-column prop="orderStatus" label="工单状态">
                            </el-table-column> -->
                                <el-table-column prop="deviceDepartment" label="设备所在部门">
                                </el-table-column>
                                <el-table-column prop="deviceLocation" label="设备所在地点">
                                </el-table-column>
                                <el-table-column prop="failureTime" label="故障时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="oprPerson" label="记录人">
                                </el-table-column>
                                <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="failureCode" label="故障代码">
                                </el-table-column>
                                <el-table-column prop="failureDevPart" label="故障设备部位">
                                </el-table-column>
                                <el-table-column prop="failureLevel" label="故障等级">
                                </el-table-column>
                                <el-table-column prop="failureType" label="故障类型">
                                </el-table-column>
                                <el-table-column prop="failureDesc" label="故障描述">
                                </el-table-column>
                                <el-table-column prop="failureReason" label="故障原因">
                                </el-table-column>
                                <el-table-column prop="execGroup" label="维修组">
                                </el-table-column>

                                <el-table-column prop="execPerson" label="维修人">
                                </el-table-column>
                                <el-table-column prop="orderStatus" label="维修工单状态">
                                </el-table-column>
                                <el-table-column prop="execLevel" label="维修等级">
                                </el-table-column>
                                <el-table-column prop="execType" label="维修类型">
                                </el-table-column>
                                <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）">
                                </el-table-column>
                                <el-table-column prop="urgenLevel" label="紧急程度">
                                </el-table-column>
                                <el-table-column label="是否停机">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.machineStop}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="stopLen" label="停机时长（分钟）">
                                </el-table-column>


                                <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column label="是否逾期接单">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isTakeOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="endTime" label="维修结束时间" :formatter="formatDate">
                                </el-table-column>

                                <el-table-column label="是否逾期维修结束">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEndOverdue}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                                </el-table-column>
                                <el-table-column prop="execLenM" label="维修时长（分钟）">
                                </el-table-column>
                                <el-table-column prop="execFee" label="维修费用（元）">
                                </el-table-column>
                                <el-table-column prop="spotCondition" label="现场状况">
                                </el-table-column>

                                <el-table-column prop="execDesc" label="工作描述">
                                </el-table-column>
                                <el-table-column prop="execGrade" label="工单评分等级">
                                </el-table-column>
                                <el-table-column label="是否为经验性记录">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isEmpirical}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="是否委外">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.isOutEntrust}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="remarks" label="备注">
                                </el-table-column>
                            </el-table>
                            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize"
                                :page-size="pageSize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChangeRepair">
                            </el-pagination>
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane name="1">
                        <el-badge :value="4" class="item" slot="label"><span>待办</span></el-badge>

                        <div class="flex mg_left_10">
                            <div class="flex align_center">
                                <div class="color_999999 fn_size16">待办总数：</div>
                                <div class="fn_size30">23</div>
                            </div>
                            <div class="flex align_center mg_left_30">
                                <div class="color_999999 fn_size16">今日已办：</div>
                                <div class="fn_size30">17</div>
                            </div>
                        </div>
                        <div class="mg_top_10">
                            <el-select v-model="value" placeholder="多选执行人" size="mini" class="width_140">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                            <el-select v-model="value" placeholder="多选状态" size="mini" class="mg_left_10 width_140">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                            <el-time-picker v-model="time" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"
                                placeholder="选择时间" size="mini" class="mg_left_10 dateTimeWidth"></el-time-picker>
                            <el-date-picker v-model="date" type="date" placeholder="选择日期"
                                class="mg_left_10 dateTimeWidth" size="mini"></el-date-picker>
                            <el-button type="primary" size="mini" class="select_button mg_left_10"><i
                                    class="select_icon"></i>
                            </el-button>
                        </div>
                        <div style="overflow-y:auto" class="mg_left_10 height_380 mg_top_10">
                            <el-collapse v-model="collapseNames" @change="handleChange">
                                <el-collapse-item :name="index+1" v-for="(item,index) in collapse_items" :key="index">
                                    <div class="flex justify_between fn_size16 color_1A1A1A width_100" slot="title">
                                        <span>{{item.execute_name}}</span>
                                        <span>{{item.execute_people}}</span>
                                        <span class="mg_right_30">{{item.execute_status}}</span>
                                    </div>
                                    <div class="fn_size14 color_808080">
                                        <el-row>
                                            <el-col :span="8" class="flex justify_start">{{item.name}}</el-col>
                                            <el-col :span="8" class="flex justify_start">{{item.num}}</el-col>
                                            <el-col :span="8" class="flex justify_start">{{item.createtime}}</el-col>
                                            <el-col :span="8" class="flex justify_start">{{item.createpeople}}</el-col>
                                            <el-col :span="8" class="flex justify_start">{{item.start}}</el-col>
                                            <el-col :span="8" class="flex justify_start">{{item.end}}</el-col>
                                            <el-col :span="24" class="flex justify_start">{{item.content}}</el-col>
                                        </el-row>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane label="点检" name="2">点检</el-tab-pane>
                    <el-tab-pane label="保养" name="3">保养</el-tab-pane>
                    <el-tab-pane label="维修" name="4">维修</el-tab-pane>
                    <el-tab-pane label="报警" name="5">报警</el-tab-pane>
                    <el-tab-pane label="预留" name="6">预留</el-tab-pane>
                    <el-tab-pane label="预留" name="7">预留</el-tab-pane>
                </el-tabs> -->
            </el-col>
        </el-row>

        <el-row :gutter="12">
            <el-col :span="12" class="diy_col1 ">
                <div class="bg-purple">近15天设备KPI分析</div>
                <el-row class="">
                    <el-col :span="24" class="height_vh2 bgc_FFFFFF">
                        <el-select v-model="kpiAnalysisSelectValue" placeholder="选择指标"
                            class="pull-right mg_top_10 mg_right_30 width_150" @change="changeKpiAnalysisSelect">
                            <el-option v-for="item in kpiAnalysisSelect" :key="item.value" :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div id="rentDaysEquiKPIStatistics" style="width: 100%; height: 90%;" class="mg_top_40"></div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12" class="diy_col1">
                <div class="bg-purple">生产数据对比</div>
                <el-row>
                    <el-col :span="24" class="height_vh2 bgc_FFFFFF">
                        <div class="mg_top_10 pull-right mg_right_30">
                            <el-select v-model="productionAnalysisDataSelectValue" placeholder="选择指标" class="width_150"
                                @change="changeProductionAnalysisDataSelect">
                                <el-option v-for="item in productionAnalysisDataSelect" :key="item.value"
                                    :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-select v-model="value" placeholder="选择数据维度" class="mg_left_10 width_150">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select> -->
                        </div>
                        <div id="sectionProductionDataCompare" style="width: 100%; height: 90%;" class="mg_top_40">
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <!-- 弹框 -->
        <!-- 弹框1 设备总台数 -->
        <el-dialog :visible.sync="equipmentNum_dialogTableVisible" top='10vh' width="80%">
            <div slot="title" class="fn_size20 color_000000 flex justify_center font_bold">设备详情</div>
            <el-table :data="equipmentDetailForm" class="dialog__body" height="600">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="Administrators" label="设备管理员"></el-table-column>
                <el-table-column prop="ControlSystem" label="控制系统"></el-table-column>
                <el-table-column prop="Department" label="所属部门"></el-table-column>
                <el-table-column prop="EquipmentCode" label="设备编号"></el-table-column>
                <el-table-column prop="EquipmentLevel" label="设备等级"></el-table-column>
                <el-table-column prop="EquipmentModel" label="设备型号"></el-table-column>
                <el-table-column prop="EquipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="EquipmentType" label="设备类型"></el-table-column>
                <el-table-column prop="InstallationPosition" label="安装位置"></el-table-column>
                <el-table-column prop="Purchasingdate" label="购买日期"></el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门"></el-table-column>
                <el-table-column prop="Remark" label="备注"></el-table-column>
            </el-table>
            <div class="flex justify_between align_center">
                <div class="color_808080">设备详情</div>
                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            </div>
        </el-dialog>
        <!-- 弹框1结束 -->

        <!-- 弹框2 连接异常 -->
        <el-dialog :visible.sync="errNum_dialogTableVisible" top='10vh' width="80%">
            <div slot="title" class="fn_size20 color_000000 flex justify_center font_bold">连接异常详情</div>
            <el-table :data="errDetailForm" class="dialog__body" height="600">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="Administrators" label="设备管理员"></el-table-column>
                <el-table-column prop="ControlSystem" label="控制系统"></el-table-column>
                <el-table-column prop="Department" label="所属部门"></el-table-column>
                <el-table-column prop="EquipmentCode" label="设备编号"></el-table-column>
                <el-table-column prop="EquipmentLevel" label="设备等级"></el-table-column>
                <el-table-column prop="EquipmentModel" label="设备型号"></el-table-column>
                <el-table-column prop="EquipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="EquipmentType" label="设备类型"></el-table-column>
                <el-table-column prop="InstallationPosition" label="安装位置"></el-table-column>
                <el-table-column prop="Purchasingdate" label="购买日期"></el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门"></el-table-column>
                <el-table-column prop="Remark" label="备注"></el-table-column>
            </el-table>
            <div class="flex justify_between align_center">
                <div class="color_808080">连接异常详情</div>
                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            </div>
        </el-dialog>
        <!--弹框2结束-->

        <!--弹框3 本月报修-->
        <el-dialog :visible.sync="failureReportNum_dialogTableVisible" top='10vh' width="90%">
            <div slot="title" class="fn_size20 color_000000 flex justify_center font_bold">本月报修详情</div>
            <el-table :data="failureReportDetailForm" class="dialog__body" height="600">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="authSort" label="审批顺序"></el-table-column>
                <el-table-column prop="deviceDepartment" label="设备所在部门"></el-table-column>
                <el-table-column prop="deviceID" label="设备编号"></el-table-column>
                <el-table-column prop="deviceLocation" label="设备所在地点"></el-table-column>
                <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
                <el-table-column prop="deviceName" label="设备名称"></el-table-column>
                <el-table-column prop="deviceType" label="设备类型"></el-table-column>
                <el-table-column prop="failureDesc" label="故障描述"></el-table-column>
                <el-table-column prop="failureLevel" label="故障等级"></el-table-column>
                <el-table-column prop="failureRptID" label="故障报修编号"></el-table-column>
                <el-table-column prop="failureTime" label="故障时间" :formatter="formatDate"></el-table-column>
                <el-table-column prop="failureType" label="故障类型"></el-table-column>

                <el-table-column label="是否需要审批">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isAuth == false">不需要</span>
                        <span v-if="scope.row.isAuth == true">需要</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否停机">
                    <template slot-scope="scope">
                        <span v-if="scope.row.machineStop == false">不停机</span>
                        <span v-if="scope.row.machineStop == true">停机</span>
                    </template>
                </el-table-column>
                <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                <el-table-column prop="oprPhoneNumber" label="记录人电话"></el-table-column>
                <el-table-column prop="oprTime" label="记录时间"></el-table-column>
                <el-table-column prop="orderStatus" label="审批状态"></el-table-column>
                <el-table-column prop="remarks" label="备注"></el-table-column>
            </el-table>
            <div class="flex justify_between align_center">
                <div class="color_808080">本月保修详情</div>
                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            </div>
        </el-dialog>
        <!--弹框3结束-->

        <!--弹框4 OEE低设备-->
        <el-dialog :visible.sync="lowOEENum_dialogTableVisible" top='10vh' width="80%">
            <div slot="title" class="fn_size20 color_000000 flex justify_center font_bold">OEE低设备详情</div>
            <el-table :data="lowOEEDetailForm" class="dialog__body" height="600">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="Administrators" label="设备管理员"></el-table-column>
                <el-table-column prop="ControlSystem" label="控制系统"></el-table-column>
                <el-table-column prop="Department" label="所属部门"></el-table-column>
                <el-table-column prop="EquipmentCode" label="设备编号"></el-table-column>
                <el-table-column prop="EquipmentLevel" label="设备等级"></el-table-column>
                <el-table-column prop="EquipmentModel" label="设备型号"></el-table-column>
                <el-table-column prop="EquipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="EquipmentType" label="设备类型"></el-table-column>
                <el-table-column prop="InstallationPosition" label="安装位置"></el-table-column>
                <el-table-column prop="Purchasingdate" label="购买日期"></el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门"></el-table-column>
                <el-table-column prop="Remark" label="备注"></el-table-column>
            </el-table>
            <div class="flex justify_between align_center">
                <div class="color_808080">OEE低设备详情</div>
                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            </div>
        </el-dialog>
        <!--弹框4结束-->

        <!--弹框5 开机率低-->
        <el-dialog :visible.sync="lowOperationRateNum_dialogTableVisible" top='10vh' width="80%">
            <div slot="title" class="fn_size20 color_000000 flex justify_center font_bold">开机率低详情</div>
            <el-table :data="lowOperationRateDetailForm" class="dialog__body" height="600">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="Administrators" label="设备管理员"></el-table-column>
                <el-table-column prop="ControlSystem" label="控制系统"></el-table-column>
                <el-table-column prop="Department" label="所属部门"></el-table-column>
                <el-table-column prop="EquipmentCode" label="设备编号"></el-table-column>
                <el-table-column prop="EquipmentLevel" label="设备等级"></el-table-column>
                <el-table-column prop="EquipmentModel" label="设备型号"></el-table-column>
                <el-table-column prop="EquipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="EquipmentType" label="设备类型"></el-table-column>
                <el-table-column prop="InstallationPosition" label="安装位置"></el-table-column>
                <el-table-column prop="Purchasingdate" label="购买日期"></el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门"></el-table-column>
                <el-table-column prop="Remark" label="备注"></el-table-column>
            </el-table>
            <div class="flex justify_between align_center">
                <div class="color_808080">开机率低详情</div>
                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            </div>
        </el-dialog>
        <!--弹框5结束-->

        <!--弹框6 开机利用率低-->
        <el-dialog :visible.sync="lowUseRatioNum_dialogTableVisible" top='10vh' width="80%">
            <div slot="title" class="fn_size20 color_000000 flex justify_center font_bold">开机利用率低详情</div>
            <el-table :data="lowUseRatioDetailForm" class="dialog__body" height="600">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="Administrators" label="设备管理员"></el-table-column>
                <el-table-column prop="ControlSystem" label="控制系统"></el-table-column>
                <el-table-column prop="Department" label="所属部门"></el-table-column>
                <el-table-column prop="EquipmentCode" label="设备编号"></el-table-column>
                <el-table-column prop="EquipmentLevel" label="设备等级"></el-table-column>
                <el-table-column prop="EquipmentModel" label="设备型号"></el-table-column>
                <el-table-column prop="EquipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="EquipmentType" label="设备类型"></el-table-column>
                <el-table-column prop="InstallationPosition" label="安装位置"></el-table-column>
                <el-table-column prop="Purchasingdate" label="购买日期"></el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门"></el-table-column>
                <el-table-column prop="Remark" label="备注"></el-table-column>
            </el-table>
            <div class="flex justify_between align_center">
                <div class="color_808080">开机利用率低详情</div>
                <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
            </div>
        </el-dialog>
        <!--弹框6结束-->

        <!--弹框7 点检待办单-->
        <el-dialog :visible.sync="agentPointNum_dialogTableVisible" width="70%">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">点检待办单详情</div>
            <div class="dialog__body pdb_20">
                <el-table :data="agentPointDataList" height="100%">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="orderType" label="点检类型"></el-table-column>
                    <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                    <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                    <el-table-column label="是否异常">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isAbnormal == false">无异常</span>
                            <span v-if="scope.row.isAbnormal == true">异常</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期点检结束">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEndOverdue == false">未逾期</span>
                            <span v-if="scope.row.isEndOverdue == true">逾期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                    <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                    <el-table-column width="80" label="点检结果列表">
                        <template slot-scope="props">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="agentPointLookResult(props.row)">查看</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- 点检结果列表   orderRecordList -->
                </el-table>
            </div>
        </el-dialog>
        <!--弹框7结束-->

        <!--弹框7.1 点检结果列表 弹框-->
        <el-dialog :visible.sync="dialogTableVisibleResult">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">点检结果列表</div>
            <div class="height_580 pdb_20">
                <el-table :data="orderRecordList" max-height="580">
                    <el-table-column type="index" label="序号" width="150"></el-table-column>
                    <el-table-column property="inspDeviceID" label="设备编号" width="200"></el-table-column>
                    <el-table-column property="remarks" label="备注"></el-table-column>
                    <el-table-column type="expand" label="点检内容" width="80">
                        <template slot-scope="props">
                            <el-collapse accordion>
                                <el-collapse-item v-for="(item,index) in props.row.inspDeviceContent" :key="index">
                                    <template slot="title">
                                        <span class="font_bold"> 点检ID：{{item.inspStandardID}}</span>
                                    </template>
                                    <el-card shadow="always" v-for="(items,index1) in item.inspStandardDetail"
                                        class="mg_top_10" :key="index1">
                                        <div class="flex flex_column align_start">
                                            <div class="height_30">点检详情ID：{{items.inspStandardDetailID}}</div>
                                            <div class="height_30">点检详情类型：{{items.inspStandardDetailType}}</div>
                                            <div class="height_30">点检标准详情记录：{{items.inspStandardDetailRecord}}</div>
                                            <div class="height_30">点检详情内容：{{items.inspStandardDetailContent}}</div>
                                            <div class="height_30">点检标准详情：{{items.inspStandardDetailStandard}}</div>
                                            <div class="height_30">点检详情记录状态：{{items.inspStandardDetailRecordStatus}}
                                            </div>
                                        </div>
                                    </el-card>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <!--弹框7.1结束-->

        <!--弹框8 异常单-->
        <el-dialog :visible.sync="errPointNum_dialogTableVisible" width="70%">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">异常单详情</div>
            <div class="dialog__body pdb_20">
                <el-table :data="errPointList" height="100%">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="orderType" label="点检类型"></el-table-column>
                    <el-table-column prop="orderID" label="点检工单编号"></el-table-column>
                    <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                    <el-table-column label="是否异常">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isAbnormal == false">无异常</span>
                            <span v-if="scope.row.isAbnormal == true">异常</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期点检结束">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEndOverdue == false">未逾期</span>
                            <span v-if="scope.row.isEndOverdue == true">逾期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                    <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                    <el-table-column width="80" label="点检结果列表">
                        <template slot-scope="props">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="errPointLookResult(props.row)">查看</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- 点检结果列表   orderRecordList -->
                </el-table>
            </div>
        </el-dialog>
        <!--弹框8结束-->

        <!--弹框8.1 点检结果列表 弹框-->
        <el-dialog :visible.sync="dialogTableVisibleResult2">
            <div slot="title" class="fn_size18 flex justify_center color_black font_bold">点检结果列表</div>
            <div class="height_580 pdb_20">
                <el-table :data="orderRecordList" max-height="580">
                    <el-table-column type="index" label="序号" width="150"></el-table-column>
                    <el-table-column property="inspDeviceID" label="设备编号" width="200"></el-table-column>
                    <el-table-column property="remarks" label="备注"></el-table-column>
                    <el-table-column type="expand" label="点检内容" width="80">
                        <template slot-scope="props">
                            <el-collapse accordion>
                                <el-collapse-item v-for="(item,index) in props.row.inspDeviceContent" :key="index">
                                    <template slot="title">
                                        <span class="font_bold"> 点检ID：{{item.inspStandardID}}</span>
                                    </template>
                                    <el-card shadow="always" v-for="(items,index1) in item.inspStandardDetail"
                                        class="mg_top_10" :key="index1">
                                        <div class="flex flex_column align_start">
                                            <div class="height_30">点检详情ID：{{items.inspStandardDetailID}}</div>
                                            <div class="height_30">点检详情类型：{{items.inspStandardDetailType}}</div>
                                            <div class="height_30">点检标准详情记录：{{items.inspStandardDetailRecord}}</div>
                                            <div class="height_30">点检详情内容：{{items.inspStandardDetailContent}}</div>
                                            <div class="height_30">点检标准详情：{{items.inspStandardDetailStandard}}</div>
                                            <div class="height_30">点检详情记录状态：{{items.inspStandardDetailRecordStatus}}
                                            </div>
                                        </div>
                                    </el-card>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <!--弹框8.1结束-->

        <!--弹框9 逾期单-->
        <el-dialog :visible.sync="overdueNum_dialogTableVisible" top='7vh' width="80%">
            <!-- 标题 -->
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">逾期单详情</div>
            <!--下拉选择框-->
            <div class="flex">
                <el-select v-model="overdueType" placeholder="请选择逾期单类型">
                    <el-option v-for="item in overdueOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- 点检逾期单-->
            <div class="mg_top_10 fn_size14 color_1A1A1A dialog__body" v-if="overdueType == 1">
                <div class="flex font_bold mg_top_10">
                    <span>点检逾期单</span>
                </div>
                <el-table :data="inspectionList" height="95%">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="orderType" label="点检类型"></el-table-column>
                    <el-table-column prop="orderID" label="点检工单编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="orderName" label="点检工单名"></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                    <el-table-column label="是否异常">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isAbnormal == false">无异常</span>
                            <span v-if="scope.row.isAbnormal == true">异常</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="execLenM" label="执行时间长度（分钟）"></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="startTime" label="点检开始时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="点检结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期点检结束">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEndOverdue == false">未逾期</span>
                            <span v-if="scope.row.isEndOverdue == true">逾期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueReason" label="逾期理由"></el-table-column>
                    <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="orderLocScope" label="点检位置范围"></el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                    <el-table-column width="80" label="点检结果列表">
                        <template slot-scope="props">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="overdueLookResult(props.row)">查看</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- 保养逾期单 -->
            <div class="mg_top_10 fn_size14 color_1A1A1A dialog__body" v-if="overdueType == 3">
                <div class="flex font_bold mg_top_10">
                    <span>保养逾期单</span>
                </div>
                <el-table :data="maintainList" style="width: 100%;" height="95%" class="mg_top_10">
                    <el-table-column type="index" label="序号" width="70"></el-table-column>
                    <el-table-column prop="orderID" label="保养工单编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="deviceID" label="设备编号"></el-table-column>
                    <el-table-column prop="deviceName" label="设备名称"></el-table-column>
                    <el-table-column prop="deviceType" label="设备类型"></el-table-column>
                    <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
                    <el-table-column prop="orderStatus" label="工单状态"></el-table-column>
                    <el-table-column prop="deviceDepartment" label="设备所在部门"></el-table-column>
                    <el-table-column prop="deviceLocation" label="设备所在地点"></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="execGroup" label="保养组"></el-table-column>
                    <el-table-column prop="execPerson" label="保养人"></el-table-column>
                    <el-table-column prop="execLevel" label="保养等级"></el-table-column>
                    <el-table-column prop="execType" label="保养类型"></el-table-column>
                    <el-table-column prop="execEstimateLen" label="保养预估花费时间（分钟）"></el-table-column>
                    <el-table-column  label="紧急程度">
                         <template slot-scope="scope">
                        <span>{{scope.row.urgenLevel}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否停机">
                        <template slot-scope="scope">
                            <span v-if="scope.row.machineStop == false">不停机</span>
                            <span v-if="scope.row.machineStop == true">停机</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stopLen" label="停机时长（分钟）"></el-table-column>
                    <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期接单">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isTakeOverdue == false">不逾期接单</span>
                            <span v-if="scope.row.isTakeOverdue == true">逾期接单</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="startTime" label="保养开始时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="保养结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期保养结束">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEndOverdue == false">否</span>
                            <span v-if="scope.row.isEndOverdue == true">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="execRejectStartTime" label="验证不通过保养开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execRejectEndTime" label="验证不通过保养结束时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execLenM" label="保养时长（分钟）"></el-table-column>
                    <el-table-column prop="execFee" label="保养费用（元）"></el-table-column>
                    <el-table-column prop="spotCondition" label="现场状况"></el-table-column>
                    <el-table-column prop="execDesc" label="工作描述"></el-table-column>
                    <el-table-column prop="execGrade" label="工单评分等级"></el-table-column>
                    <el-table-column label="是否为经验性记录">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEmpirical == false">否</span>
                            <span v-if="scope.row.isEmpirical == true">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否委外">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isOutEntrust == false">否</span>
                            <span v-if="scope.row.isOutEntrust == true">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="execPersonNick" label="保养人员昵称名单"></el-table-column>
                    <el-table-column prop="assignEndTime" label="指定保养结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="verifyRemarks" label="验证备注"></el-table-column>
                    <el-table-column prop="verifyTime" label="验证时间（备用）" :formatter="formatDate"></el-table-column>
                </el-table>
            </div>

            <!-- 维修逾期单 -->
            <div class="mg_top_10 fn_size14 color_1A1A1A dialog__body" v-if="overdueType == 2">
                <div class="flex font_bold mg_top_10">
                    <span>维修逾期单</span>
                </div>
                <el-table :data="repairList" style="width: 100%;" height="95%" class="mg_top_10">
                    <el-table-column type="index" label="序号" width="70"></el-table-column>
                    <el-table-column prop="orderID" label="维修工单编号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="deviceID" label="设备编号"></el-table-column>
                    <el-table-column prop="deviceName" label="设备名称"></el-table-column>
                    <el-table-column prop="deviceType" label="设备类型"></el-table-column>
                    <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
                    <el-table-column prop="deviceDepartment" label="设备所在部门"></el-table-column>
                    <el-table-column prop="deviceLocation" label="设备所在地点"></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人"></el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="failureCode" label="故障代码"></el-table-column>
                    <el-table-column prop="failureDevPart" label="故障设备部位"></el-table-column>
                    <el-table-column prop="failureLevel" label="故障等级"></el-table-column>
                    <el-table-column prop="failureType" label="故障类型"></el-table-column>
                    <el-table-column prop="failureDesc" label="故障描述"></el-table-column>
                    <el-table-column prop="failureReason" label="故障原因"></el-table-column>
                    <el-table-column prop="execPerson" label="维修人"></el-table-column>
                    <el-table-column prop="orderStatus" label="维修工单状态"></el-table-column>
                    <el-table-column prop="execLevel" label="维修等级"></el-table-column>
                    <el-table-column prop="execType" label="维修类型"></el-table-column>
                    <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）"></el-table-column>
                    <el-table-column prop="urgenLevel" label="紧急程度"></el-table-column>
                    <el-table-column label="是否停机">
                        <template slot-scope="scope">
                            <span v-if="scope.row.machineStop == false">否</span>
                            <span v-if="scope.row.machineStop == true">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stopLen" label="停机时长（分钟）"></el-table-column>
                    <el-table-column label="是否逾期接单">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isTakeOverdue == false">不逾期</span>
                            <span v-if="scope.row.isTakeOverdue == true">逾期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="endTime" label="维修结束时间" :formatter="formatDate"></el-table-column>
                    <el-table-column label="是否逾期维修结束">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEndOverdue == false">不逾期</span>
                            <span v-if="scope.row.isEndOverdue == true">逾期</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate"></el-table-column>
                    <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execLenM" label="维修时长（分钟）"></el-table-column>
                    <el-table-column prop="execFee" label="维修费用（元）"></el-table-column>
                    <el-table-column prop="spotCondition" label="现场状况"></el-table-column>
                    <el-table-column prop="execDesc" label="工作描述"></el-table-column>
                    <el-table-column prop="execGrade" label="工单评分等级"></el-table-column>
                    <el-table-column label="是否为经验性记录">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isEmpirical == false">否</span>
                            <span v-if="scope.row.isEmpirical == true">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否委外">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isOutEntrust == false">否</span>
                            <span v-if="scope.row.isOutEntrust == true">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注"></el-table-column>
                </el-table>
            </div>

            <!-- 弹框页尾 -->
            <div class="flex justify_between align_center">
                <div class="color_808080">逾期单详情</div>
            </div>

        </el-dialog>
         <!-- 点检逾期单-点检结果列表 -->
            <el-dialog :visible.sync="dialogTableVisibleResult3">
                <div slot="title" class="fn_size18 flex justify_center color_black font_bold">点检结果列表</div>
                <div class="height_580 pdb_20">
                    <el-table :data="orderRecordList" max-height="580">
                        <el-table-column type="index" label="序号" width="150"></el-table-column>
                        <el-table-column property="inspDeviceID" label="设备编号" width="200"></el-table-column>
                        <el-table-column property="remarks" label="备注"></el-table-column>
                        <el-table-column type="expand" label="点检内容" width="80">
                            <template slot-scope="props">
                                <el-collapse accordion>
                                    <el-collapse-item v-for="(item,index) in props.row.inspDeviceContent" :key="index">
                                        <template slot="title">
                                            <span class="font_bold"> 点检ID：{{item.inspStandardID}}</span>
                                        </template>
                                        <el-card shadow="always" v-for="(items,index1) in item.inspStandardDetail"
                                            class="mg_top_10" :key="index1">
                                            <div class="flex flex_column align_start">
                                                <div class="height_30">点检详情ID：{{items.inspStandardDetailID}}</div>
                                                <div class="height_30">点检详情类型：{{items.inspStandardDetailType}}</div>
                                                <div class="height_30">点检标准详情记录：{{items.inspStandardDetailRecord}}</div>
                                                <div class="height_30">点检详情内容：{{items.inspStandardDetailContent}}</div>
                                                <div class="height_30">点检标准详情：{{items.inspStandardDetailStandard}}</div>
                                                <div class="height_30">点检详情记录状态：{{items.inspStandardDetailRecordStatus}}
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-collapse-item>
                                </el-collapse>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-dialog>
        <!--弹框9结束-->

    </div>
</template>
<script>
    //   import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        indicatorData, //指标数据
        indicatorDetailList, //指标详细清单
        //kpiStatisticDimensionalitySelector, //KPI统计-维度下拉选
        //kpiStatisticEntitySelector, //KPI统计-实体下拉选
        kpiStatisticOrganizationSelect, //组织架构选择
        productionInfo, //生产数据仪表盘、折线图
        kpiStatistic, //KPI统计
        kpiAnalysisIndicatorSelector, //Kpi分析指标下拉选
        kpiAnalysis, //Kpi分析
        productionAnalysisDataSelector, //生产数据分析下拉框
        productionAnalysis, //生产数据分析

        // 生产信息
        productionWaitDoneCount,
        productionDoneExecutorSelect,
        productionWaitDoneWorkOrderList,
        repairWaitDoneTableSelect,

        productionPointInspectioExecutorSelect,
        productionPointWorkOrderExecutorSelect,
        productionPointInspectioWorkOrderList,

        productionMaintainExecutorSelect,
        productionMaintainWorkOrderExecutorSelect,
        productionMaintainWorkOrderList,

        productionRepairExecutorSelect,
        productionRepairWorkOrderExecutorSelect,
        productionRepairWorkOrderList
    } from "@/api/api_branchFactory";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                branchFactorySelect: '1', //统计数据-下拉框
                dataFrame1: [{ //统计数据-下拉框
                    value: '1',
                    label: '日'
                }, {
                    value: '2',
                    label: '月'
                }],
                //统计数据
                equipmentTotal: {
                    equipmentNum: '', //设备总数
                    equipmentIDs: '',
                    indicatorType: '',
                }, //设备总台数
                errTotal: {
                    errNum: '', //连接异常总数
                    errNumIDs: '',
                    indicatorType: '',
                }, //连接异常
                failureReportTotal: {
                    failureReportIDs: '',
                    failureReportNum: '', //本月报修总数
                    indicatorType: '',
                }, //本月报修
                lowOEETotal: {
                    lowOEENum: '', //OEE低设备总数
                    OEEIDs: '',
                    indicatorType: '',
                }, //OEE低设备
                lowOperationRateTotal: {
                    lowOperationRateNum: '', //开机率低总数
                    OperationIDs: '',
                    indicatorType: '',
                }, //开机率低
                lowUseRatioTotal: {
                    lowUseRatioNum: '', //开机利用率低总数
                    UseRatioIDs: '',
                    indicatorType: '',
                }, //开机利用率低
                agentPointTotal: {
                    agentPointNum: '', //点检待办单总数
                    indicatorType: '',
                }, //点检待办单
                agentPointDataList: [], //点检待办单详细数据
                orderRecordList: [],

                errPointTotal: {
                    errPointNum: '', //异常单
                    indicatorType: '',
                }, //异常单
                errPointList: [],


                //逾期单
                overdueNum: '', //逾期单总数 
                inspectionList: [], //点检逾期单
                maintainList: [], //保养逾期单
                repairList: [], //维修逾期

                equipmentNum_dialogTableVisible: false, //设备总数-详情弹框控制符
                errNum_dialogTableVisible: false, //连接异常-详情弹框控制符
                failureReportNum_dialogTableVisible: false, //本月报修-详情弹框控制符
                lowOEENum_dialogTableVisible: false, //OEE低设备-详情弹框控制符
                lowOperationRateNum_dialogTableVisible: false, //开机率低-详情弹框控制符
                lowUseRatioNum_dialogTableVisible: false, //开机利用率低-详情弹框控制符
                agentPointNum_dialogTableVisible: false, //点检待办-详情弹框控制符
                dialogTableVisibleResult: false,
                errPointNum_dialogTableVisible: false, //异常单-详情弹框控制符
                dialogTableVisibleResult2: false,
                overdueNum_dialogTableVisible: false, //逾期单-详情弹框控制符
                dialogTableVisibleResult3: false,

                equipmentDetailForm: [], //设备总数详情数组
                errDetailForm: [], //连接异常详情数组
                failureReportDetailForm: [], //本月报修详情数组
                lowOEEDetailForm: [], //OEE低设备详情数组
                lowOperationRateDetailForm: [], //开机率低详情数组
                lowUseRatioDetailForm: [], //开机利用率低详情数组
                agentPointDetailForm: [], //点检待办详情数组
                errPointDetailForm: [], //异常单详情数组
                overdueDetailForm: [], //逾期单详情数组

                overdueType: 2,
                overdueOptions: [{
                    value: 1,
                    label: '点检逾期单'
                }, {
                    value: 2,
                    label: '维修逾期单'
                }, {
                    value: 3,
                    label: '保养逾期单'
                }],

                //组织架构选择-级联选择器
                TreeList: [],
                standardArr: [],
                //currentData:[],
                standardData: [],
                organizationValue: [],
                organizationAr: [],
                defaultProps: {
                    label: 'Name',
                    value: 'ID',
                    expandTrigger: 'hover',
                    multiple: true, // 多选
                    emitPath: false, // 只返回选中节点的值
                    checkStrictly: true
                }, //级联选择器绑定值
                produceTime: null,
                checkData: {},

                //统计数据
                //KPI统计
                kpiStatisticList: {
                    OEEValue: 0,
                    PowerOnUtilizationValue: 0,
                    PowerOnRateValue: 0,
                }, //三个圈中的数据

                //前一天计划和实际统计
                yesterdayOutput: {
                    ActualOutput: 0,
                    PlanOutput: 0,
                    value: 0.00,
                },
                //当天计划和实际统计
                todayOutput: {
                    ActualOutput: 0,
                    PlanOutput: 0,
                    value: 0.00,
                },

                //KPI设计维度
                kpiStatisticDimension: [],
                kpiStatisticDimensionValue: '',
                //
                //KPI设计实体
                kpiStatisticEntity: [],
                kpiStatisticEntityValue: '',
                //
                //Kpi分析指标下拉选
                productionAnalysisDataSelect: [],
                productionAnalysisDataSelectValue: '',
                //
                //生产数据分析下拉框
                kpiAnalysisSelect: [],
                kpiAnalysisSelectValue: '',
                //
                statisticalDataTime: [new Date(new Date().toLocaleDateString()), new Date()], //统计数据-选择时间范围
                // statisticalDataTime: [new Date().getTime() - 3600 * 1000 * 24 * 1, new Date().getTime()], //统计数据-选择时间范围
                //近15天-开始
                nearlyStartDate: [new Date().getTime() - 1000 * 60 * 60 * 24 * 15],
                nearlyEndDate: [new Date().getTime()],
                //近15天-结束
                //权限-开始
                permission: {},
                //权限-结束
                options: [],
                value: '',
                time: '',
                date: '',
                visible: false,
                collapseNames: '',
                activeName: 'first',
                collapse_items: [],
                gridData: [],
                userID: '',

                waitDoneCount: {},
                option_peopleValue: [],
                option_people: [],
                waitDoneTableArValue: '',
                waitDoneTableAr: [],
                waitDoneTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                waitDoneList: [],
                currentTable: 1,
                // 时 分 秒 毫秒

                djPeopleArValue: [],
                djPeopleAr: [],
                spotOrderStatusValue: [],
                spotOrderStatus: [],
                djTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                djList: [],

                maintainPeopleValue: [],
                maintainPeople: [],
                maintainStatusValue: [],
                maintainStatus: [],
                maintainTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                maintainList: [],

                repairPeopleValue: [],
                repairPeople: [],
                repairStatusValue: [],
                repairStatus: [],
                repairTime: [new Date().getTime() - 720 * 60 * 60 * 1000, new Date()],
                repairList: [],

                currentPage: 1,
                pageSize: 5,
                pageQuantity: 0,
                loading:true
            }
        },
        mounted() {
            //权限
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            };
            console.log(this.permission)
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }

            this.drawShape();
            this.indicatorData(); //指标数据
            // this.indicatorDetailList(); //指标详细清单
            //this.kpiStatisticDimensionalitySelector(); //KPI统计-维度下拉选
            //this.kpiStatisticEntitySelector(); //KPI统计-实体下拉选
            this.kpiStatisticOrganizationSelect(); //组织架构选择
            //this.kpiStatistic(); ////KPI统计
            // this.productionInfo();//生产数据仪表盘、折线图
            // this.kpiAnalysisIndicatorSelector(); //Kpi分析指标下拉选
            // this.kpiAnalysis();//Kpi分析
            // this.productionAnalysisDataSelector(); //生产数据分析下拉框
            // this.productionAnalysis();//生产数据分析
            this.productionWaitDoneCount();
            this.productionDoneExecutorSelect();
            this.repairWaitDoneTableSelect();
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            handleChange() {},
            //接口-开始------------
            //指标数据-开始
            async indicatorData() {
                var param = {
                    userID: this.userID,
                    dateType: this.branchFactorySelect, //统计数据-时间维度下拉框（默认1）
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                }
                // console.log(param);
                const selectRes = await indicatorData(param);
                if (selectRes.data) { //如果有返回数据则赋值
                    //设备总台数
                    this.equipmentTotal.equipmentIDs = selectRes.data.equipmentTotal.equipmentIDs;
                    this.equipmentTotal.indicatorType = selectRes.data.equipmentTotal.indicatorType;
                    this.equipmentTotal.equipmentNum = selectRes.data.equipmentTotal.equipmentNum;

                    //连接异常
                    this.errTotal.errNumIDs = selectRes.data.errTotal.errNumIDs;
                    this.errTotal.indicatorType = selectRes.data.errTotal.indicatorType;
                    this.errTotal.errNum = selectRes.data.errTotal.errNum;

                    //本月报修
                    this.failureReportTotal.indicatorType = selectRes.data.failureReportTotal.indicatorType;
                    this.failureReportTotal.failureReportIDs = selectRes.data.failureReportTotal.failureReportIDs;
                    this.failureReportTotal.failureReportNum = selectRes.data.failureReportTotal.failureReportNum;

                    //OEE低设备
                    this.lowOEETotal.lowOEENum = selectRes.data.lowOEETotal.lowOEENum;
                    this.lowOEETotal.OEEIDs = selectRes.data.lowOEETotal.OEEIDs;
                    this.lowOEETotal.indicatorType = selectRes.data.lowOEETotal.indicatorType;

                    //开机率低
                    this.lowOperationRateTotal.lowOperationRateNum = selectRes.data.lowOperationRateTotal
                        .lowOperationRateNum;
                    this.lowOperationRateTotal.OperationIDs = selectRes.data.lowOperationRateTotal.OperationIDs;
                    this.lowOperationRateTotal.indicatorType = selectRes.data.lowOperationRateTotal.indicatorType;

                    //开机利用率低
                    this.lowUseRatioTotal.indicatorType = selectRes.data.lowUseRatioTotal.indicatorType;
                    this.lowUseRatioTotal.UseRatioIDs = selectRes.data.lowUseRatioTotal.UseRatioIDs
                    this.lowUseRatioTotal.lowUseRatioNum = selectRes.data.lowUseRatioTotal.lowUseRatioNum;

                    //点检待办单
                    this.agentPointTotal.agentPointNum = selectRes.data.agentPointTotal.agentPointNum;
                    this.agentPointTotal.indicatorType = selectRes.data.agentPointTotal.indicatorType;
                    this.agentPointDataList = selectRes.data.agentPointTotal.agentPointDataList;

                    //异常单
                    this.errPointTotal.errPointNum = selectRes.data.errPointTotal.errPointNum;
                    this.errPointTotal.indicatorType = selectRes.data.errPointTotal.indicatorType;
                    this.errPointList = selectRes.data.errPointTotal.errPointList;

                    //逾期单
                    this.overdueNum = selectRes.data.overdueTotal.overdueNum;
                    this.maintainList = selectRes.data.overdueTotal.maintainList.list;
                    this.repairList = selectRes.data.overdueTotal.repairList.list;
                    this.inspectionList = selectRes.data.overdueTotal.inspectionList.list;
                }
                // console.log(selectRes.data)
            },
            //1 设备总台数详情
            //1.1 点击‘设备总台数’模块时触发的方法
            equipmentDetailDialog() {

                //（1）装载 设备总台数详情 弹框数据
                this.equipmentDetail();
                //（2）打开设备总台数详情弹框
                this.equipmentNum_dialogTableVisible = true;
            },
            //1.2 设备总台数详情查询方法
            async equipmentDetail() {

                //封装参数
                var param = {
                    IDs: this.equipmentTotal.equipmentIDs,
                    indicatorType: this.equipmentTotal.indicatorType,
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                };
                //console.log(param);

                //调用接口
                const selectRes = await indicatorDetailList(param);
                console.log(selectRes)

                //保存数据
                if (selectRes) { //如果有返回数据则赋值
                    this.equipmentDetailForm = selectRes.data;
                }
            },
            //2 连接异常
            //2.1 点击‘连接异常’模块时触发的方法
            errDetailDialog() {

                //（1）装载 连接异常详情 弹框数据
                this.errDetail();
                //（2）打开连接异常详情 弹框
                this.errNum_dialogTableVisible = true;
            },
            //2.2 连接异常详情查询方法
            async errDetail() {
                //封装参数
                var param = {
                    IDs: this.errTotal.errNumIDs,
                    indicatorType: this.errTotal.indicatorType,
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                };

                //调用接口
                const selectRes = await indicatorDetailList(param);
                console.log(selectRes)

                //保存数据
                if (selectRes) { //如果有返回数据则赋值
                    this.errDetailForm = selectRes.data;
                }
            },

            //3 本月报修
            //3.1 点击‘本月保修’模块时触发的方法
            failureReportDetailDialog() {
                //（1）装载 本月保修详情 弹框数据
                this.failureReportDetail();
                //（2）打开本月保修详情 弹框
                this.failureReportNum_dialogTableVisible = true;
            },
            //3.2 本月保修详情查询方法
            async failureReportDetail() {
                //封装参数
                var param = {
                    IDs: this.failureReportTotal.failureReportIDs,
                    indicatorType: this.failureReportTotal.indicatorType,
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                };

                //调用接口
                const selectRes = await indicatorDetailList(param);
                console.log(selectRes)

                //保存数据
                if (selectRes) { //如果有返回数据则赋值
                    this.failureReportDetailForm = selectRes.data;
                }
            },

            //4.OEE低设备
            //4.1 点击‘OEE低设备’模块时触发的方法
            lowOEEDetailDialog() {

                //（1）装载 OEE低设备详情 弹框数据
                this.lowOEEDetail();
                //（2）打开OEE低设备详情 弹框
                this.lowOEENum_dialogTableVisible = true;
            },
            //4.2 OEE低设备详情查询方法
            async lowOEEDetail() {
                //封装数据
                var param = {
                    IDs: this.lowOEETotal.OEEIDs,
                    indicatorType: this.lowOEETotal.indicatorType,
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                };

                //调用接口
                const selectRes = await indicatorDetailList(param);
                console.log(selectRes)

                //保存数据
                if (selectRes) { //如果有数据返回
                    this.lowOEEDetailForm = selectRes.data;
                }
            },

            //5 开机率低
            //5.1 点击‘开机率低’模块时触发的方法
            lowOperationRateNumDetailDialog() {

                //（1）装载 开机率低详情 弹框数据
                this.lowOperationRateDetail();
                //（2）打开 开机率低详情 弹框
                this.lowOperationRateNum_dialogTableVisible = true;
            },
            //5.2 开机率低详情查询方法
            async lowOperationRateDetail() {
                //封装数据
                var param = {
                    IDs: this.lowOperationRateTotal.OperationIDs,
                    indicatorType: this.lowOperationRateTotal.indicatorType,
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                };

                //调用查询接口
                const selectRes = await indicatorDetailList(param);
                console.log(selectRes)

                //保存数据
                if (selectRes) { //如果有返回数据则赋值
                    this.lowOperationRateDetailForm = selectRes.data;
                }
            },

            //6 开机利用率低
            //6.1 点击‘开机利用率’模块时触发的方法
            lowUseRatioDetailDialog() {

                //（1）装载 开机利用率低详情 弹框数据
                this.lowUseRatioDetail();
                //（2）打开 开机利用率低详情 弹框
                this, this.lowUseRatioNum_dialogTableVisible = true;
            },
            //6.2 开机利用率低详情 查询方法
            async lowUseRatioDetail() {
                //封装数据
                var param = {
                    IDs: this.lowUseRatioTotal.UseRatioIDs,
                    indicatorType: this.lowUseRatioTotal.indicatorType,
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    permission: this.permission,
                };

                //调用接口
                const selectRes = await indicatorDetailList(param);
                console.log(selectRes)

                //保存数据
                if (selectRes) { //如果有返回数据则赋值
                    this.lowUseRatioDetailForm = selectRes.data;
                }
            },
            //7. 点检待办单
            //7.1 点击 点检待办单详情弹框中的‘详情’按钮时触发的方法
            agentPointLookResult(row) {
                this.orderRecordList = [];
                this.orderRecordList = row.orderRecordList;
                this.dialogTableVisibleResult = true;
            },

            //8. 异常单
            //8.1 点击 异常单详情弹框中的‘详情’按钮时触发的方法
            errPointLookResult(row) {
                this.orderRecordList = [];
                this.orderRecordList = row.orderRecordList;
                this.dialogTableVisibleResult2 = true;
            },

            //9.逾期单
            //9.1 点击 逾期单详情弹框中的‘详情’按钮时触发的方法
            overdueLookResult(row) {
                this.orderRecordList = [];
                this.orderRecordList = row.orderRecordList;
                this.dialogTableVisibleResult3 = true;
            },

            //组织架构选择 级联选择框
            async kpiStatisticOrganizationSelect() {
                //封装参数
                var param = {
                    permission: this.permission,
                };
                //调用接口
                const selectRes = await kpiStatisticOrganizationSelect(param);
                this.organizationAr = selectRes.data.Tree;
                this.standardArr = selectRes.data.organizationPermissionAll;
                this.checkData = selectRes.data.organizationPermissionAll[0];
                this.kpiStatistic();
                 this.productionInfo();
                this.kpiAnalysisIndicatorSelector(); //Kpi分析指标下拉选
                // this.kpiAnalysis(); //Kpi分析
                this.productionAnalysisDataSelector(); //生产数据分析下拉框
                // this.productionAnalysis(); //生产数据分析
                this.loading = false;

            },
            changeOrganization() {
                console.log(this.organizationValue)
                if (this.organizationValue.length == 0) {
                    this.checkData = this.standardArr[0];
                    this.kpiStatistic();
                    this.productionInfo();
                    this.kpiAnalysisIndicatorSelector();
                    this.kpiAnalysis();
                    this.productionAnalysis();
                    return
                }
                const obj = this.$refs['demoCascader'].getCheckedNodes()
                // console.log(obj)
                var currentData = {
                    Type: [],
                    ID: []
                };
                for (var i = 0; i < obj.length; i++) {
                    var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
                    console.log(authorityadmin)

                    if (authorityadmin.length == 0) {
                        this.$message({
                            message: '警告哦，您没有权限选择此项',
                            type: 'warning'
                        });
                        this.organizationValue = []; //清空选项
                        // this.$refs['demoCascader'].clearCheckedNodes()
                        return
                    }
                    currentData.Type.push(authorityadmin[0].Type);
                    var idList = authorityadmin[0].ID;
                    for (var j = 0; j < idList.length; j++) {
                        var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
                        console.log(authorityadminTwo)
                        if (authorityadminTwo.length == 0) {
                            this.$message({
                                message: '警告哦，您没有权限选择此项',
                                type: 'warning'
                            });
                            this.organizationValue = [];
                            return
                        } else {
                            for (var z = 0; z < currentData.Type.length; z++) {
                                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                                    this.$message({
                                        message: '警告哦，请相同级别选项',
                                        type: 'warning'
                                    });
                                    this.organizationValue = [];
                                    return
                                }
                            }
                            //  currentData.Type = authorityadmin[0].Type;
                            currentData.ID.push(authorityadminTwo[0])
                            break
                        }
                    }
                    // }
                }
                console.log(currentData)
                var testData = {
                    Type: '',
                    ID: []
                }
                // for (var s = 0; s < currentData.length; s++) {
                testData.Type = currentData.Type[0];
                testData.ID = currentData.ID;
                // }
                this.checkData = testData;
                this.kpiStatistic();
                this.productionInfo();
                this.kpiAnalysisIndicatorSelector();
                this.kpiAnalysis();
                this.productionAnalysis();
            },

            //生产数据仪表盘、折线图
            async productionInfo() {

                //封装参数
                // var testData = {
                //     Type: this.checkData.Type,
                //     ID: this.checkData.ID
                // };

                var param = {
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    organization: this.checkData,
                    //testData
                };
                // console.log(param.organization)
                //调用接口
                const selectRes = await productionInfo(param);
                //装载数据
                if (selectRes.data) { //如果有返回值则复制
                    for (var i = 0; i < 2; i++) {
                        if (selectRes.data[i].date == '当天') {
                            this.todayOutput.ActualOutput = selectRes.data[i].currentActualOutput;
                            this.todayOutput.PlanOutput = selectRes.data[i].currentPlanOutput;
                            if(this.todayOutput.PlanOutput != 0){
                                this.todayOutput.value = selectRes.data[i].currentActualOutput / selectRes.data[i]
                                .currentPlanOutput;
                            }
                            //this.todayOutput.value = parseFloat(this.todayOutput.value).toFixed(2)
                        } else {
                            this.yesterdayOutput.ActualOutput = selectRes.data[i].currentActualOutput;
                            this.yesterdayOutput.PlanOutput = selectRes.data[i].currentPlanOutput;
                            if(this.yesterdayOutput.PlanOutput != 0){
                                this.yesterdayOutput.value = selectRes.data[i].currentActualOutput / selectRes.data[i]
                                .currentPlanOutput;
                            }
                            //this.yesterdayOutput.value = parseFloat(this.yesterdayOutput.value).toFixed(2)
                        }
                    }
                }

                //刷新页面
                this.drawShape();
            },

            //下拉框（日、月、季度、年）
            changeBranchFactorySelect() {
                this.indicatorData();
                this.productionInfo();
                this.kpiStatistic();

                this.kpiAnalysis();
                this.productionAnalysis();
            },
            //时间范围选择器
            changeBranchFactoryDateTime() {
                this.activeName = 'first',
                    this.option_peopleValue = [];

                this.djPeopleArValue = [];
                this.spotOrderStatusValue = [];

                this.maintainPeopleValue = [];
                this.maintainStatusValue = [];

                this.repairPeopleValue = [];
                this.repairStatusValue = [];
                this.indicatorData();
                this.productionInfo();
                this.kpiStatistic();

                this.kpiAnalysis();
                this.productionAnalysis();

                // 生产信息
                this.productionWaitDoneCount();
                this.productionDoneExecutorSelect();
                this.repairWaitDoneTableSelect();
            },
            //指标数据-结束
            //指标详细清单-开始
            // async indicatorDetailList() {
            //     var param = {
            //         // indicatorType: '',
            //         // dateType: '',
            //         // startDate: '',
            //         // endDate: '',
            //         // permission: '',
            //     }
            //     // console.log(param);
            //     const selectRes = await indicatorDetailList(param);
            //     // this.deviceTypeList = selectRes.data.rows;
            //     // console.log(selectRes.data)
            // },
            //指标详细清单-结束
            //KPI统计-维度下拉选-开始
            /*             async kpiStatisticDimensionalitySelector() {
                            var param = {
                                // permission: '',
                            }
                            // console.log(param);
                            const selectRes = await kpiStatisticDimensionalitySelector(param);
                            this.kpiStatisticDimension = selectRes.data;
                            // console.log(selectRes.data)
                        }, */
            changeKpiStatisticDimension() {

            },
            //KPI统计-维度下拉选-结束
            //KPI统计-实体下拉选-开始
            /*             async kpiStatisticEntitySelector() {
                            var param = {
                                // dimensionalityType: '',
                            }
                            // console.log(param);
                            const selectRes = await kpiStatisticEntitySelector(param);
                            // this.kpiStatisticDimension = selectRes.data;
                            // console.log(selectRes.data)
                        }, */
            changeKpiStatisticEntity() {

            },
            //KPI统计-实体下拉选-结束
            //KPI统计-开始
            async kpiStatistic() {
                //封装参数
                // var testData = {
                //     Type: this.checkData.Type,
                //     ID: this.checkData.ID
                // };
                var param = {
                    dateType: this.branchFactorySelect,
                    startDate: this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss'),
                    endDate: this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss'),
                    organization: this.checkData,
                };
                //console.log(param.organization)

                //调用接口
                const selectRes = await kpiStatistic(param);
                // console.log(selectRes)

                //装载数据
                this.kpiStatisticList.OEEValue = 0;
                this.kpiStatisticList.PowerOnUtilizationValue = 0;
                this.kpiStatisticList.PowerOnRateValue = 0;
                if (selectRes) { //如果有返回值
                    for (var i = 0; i < 3; i++) {
                        if (selectRes.data[i].name == 'OEE') {
                            this.kpiStatisticList.OEEValue = selectRes.data[i].value;
                        } else if (selectRes.data[i].name == 'PowerOnUtilization') {
                            this.kpiStatisticList.PowerOnUtilizationValue = selectRes.data[i].value;
                        } else {
                            this.kpiStatisticList.PowerOnRateValue = selectRes.data[i].value;
                        }
                    }
                }
                console.log(this.kpiStatisticList)
                this.drawShape()
            },
            //KPI统计-结束
            //生产数据仪表盘、折线图-开始
            // async productionInfo() {
            //     var param = {
            //         dimensionalityType: '',
            //         dateType: '',
            //         id: '',
            //         startDate: '',
            //         endDate: '',
            //     }
            // console.log(param);
            //     const selectRes = await productionInfo(param);
            // this.deviceTypeList = selectRes.data.rows;
            //     console.log(selectRes.data)
            // },
            //生产数据仪表盘、折线图-结束

            //Kpi分析指标下拉选数据装载
            async kpiAnalysisIndicatorSelector() {
                //封装参数
                var param = {
                    organization: this.checkData,
                }
                //调用接口
                const selectRes = await kpiAnalysisIndicatorSelector(param);
                //装载下拉选数据
                this.kpiAnalysisSelect = selectRes.data;
                this.kpiAnalysisSelectValue = selectRes.data[0].value;
                this.kpiAnalysis();
            },
            changeKpiAnalysisSelect() {
                this.kpiAnalysis();
            },
            // 近15天设备分析
            async kpiAnalysis() {
                var param = {
                    organization: this.checkData,
                    dateType: this.branchFactorySelect,
                    typeKpi: this.kpiAnalysisSelectValue
                }
                if (this.statisticalDataTime != null) {
                    param.startDate = this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss');
                    param.endDate = this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss');
                }
                const res = await kpiAnalysis(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.time;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: [],
                        type: 'bar',
                        barGap: 0,
                        barWidth: 10,
                        smooth: true
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].list;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.draw15KPIShape(line);
            },

            //Kpi分析指标下拉选-结束
            //Kpi分析-开始
            //Kpi分析-结束
            //生产数据分析下拉框-开始
            async productionAnalysisDataSelector() {
                var param = {
                    organization: this.checkData,
                }
                // console.log(param);
                const selectRes = await productionAnalysisDataSelector(param);
                this.productionAnalysisDataSelect = selectRes.data;
                this.productionAnalysisDataSelectValue = selectRes.data[0].value;
                // console.log(selectRes.data)
                this.productionAnalysis(); //生产数据分析
            },
            changeProductionAnalysisDataSelect() {
                this.productionAnalysis()
            },
            //生产数据分析下拉框-开始
            //生产数据分析-开始
            // 生产数据分析
            async productionAnalysis() {
                var param = {
                    organization: this.checkData,
                    dateType: this.branchFactorySelect,
                    typeKpi: this.productionAnalysisDataSelectValue
                }
                if (this.statisticalDataTime != null) {
                    param.startDate = this.tools.formatDate(this.statisticalDataTime[0], 'yyyy-MM-ddThh:mm:ss');
                    param.endDate = this.tools.formatDate(this.statisticalDataTime[1], 'yyyy-MM-ddThh:mm:ss');
                }
                const res = await productionAnalysis(param);
                var line = {
                    xAxis: {
                        data: []
                    },
                    legend: [],
                    series: []
                };
                line.xAxis.data = res.data.time;
                for (var i = 0; i < res.data.dataList.length; i++) {
                    let testData = {
                        name: '',
                        data: [],
                        type: 'bar',
                        barGap: 0,
                        barWidth: 12,
                        smooth: true
                    }
                    testData.name = res.data.dataList[i].name;
                    testData.data = res.data.dataList[i].list;
                    line.series.push(testData);
                    line.legend.push(res.data.dataList[i].name)
                }
                this.drawProductDataShape(line);
            },
            //生产数据分析-结束

            // 待办--开始
            async productionWaitDoneCount() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionWaitDoneCount(param);
                this.waitDoneCount = selectRes.data
            },
            async productionDoneExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionDoneExecutorSelect(param);
                this.option_people = selectRes.data;
                // this.option_peopleValue = selectRes.data[0].value;
            },
            async repairWaitDoneTableSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await repairWaitDoneTableSelect(param);
                this.waitDoneTableAr = selectRes.data;
                this.waitDoneTableArValue = selectRes.data[0].value;
                this.productionWaitDoneWorkOrderList();
            },
            async productionWaitDoneWorkOrderList() {
                var param = {
                    permission: this.permission,
                    // execPersonStr: this.option_peopleValue,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    // startDate: this.tools.formatDate(this.waitDoneTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    // this.statisticalDataTime[0]
                    startDate: this.statisticalDataTime[0],
                    endDate: this.statisticalDataTime[1],
                    type: this.waitDoneTableArValue
                }
                if (this.option_peopleValue.length != 0) {
                    param.execPersonStr = this.option_peopleValue.join(',')
                }
                const selectRes = await productionWaitDoneWorkOrderList(param);
                this.waitDoneList = selectRes.data.list.rows;
                this.pageQuantity = selectRes.data.list.pageQuantity;
                this.currentTable = this.waitDoneTableArValue;
            },
            handleCurrentChangeWaitDone() {
                this.productionWaitDoneWorkOrderList(); //查询table数据的方法
            },
            queryWaitDone() {
                this.currentPage = 1;
                this.productionWaitDoneWorkOrderList();
            },
            // // 待办--结束

            // 点检--开始 
            async repairPointInspectioExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionPointInspectioExecutorSelect(param);
                this.djPeopleAr = selectRes.data;
                // this.djPeopleArValue = selectRes.data[0].value;
                // this.repairPointInspectioWorkOrderExecutorSelect()
            },
            async repairPointInspectioWorkOrderExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionPointWorkOrderExecutorSelect(param);
                this.spotOrderStatus = selectRes.data;
                // this.spotOrderStatusValue = selectRes.data[0].value;
                // this.repairPointInspectioWorkOrderList();
            },
            async repairPointInspectioWorkOrderList() {
                var param = {
                    permission: this.permission,
                    // startDate: this.tools.formatDate(this.djTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    // endDate: this.djTime[1],
                    startDate: this.statisticalDataTime[0],
                    endDate: this.statisticalDataTime[1],
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.djPeopleArValue.length != 0) {
                    param.execPersonStr = this.djPeopleArValue.join(',')
                }
                if (this.spotOrderStatusValue.length != 0) {
                    param.orderStatus = this.spotOrderStatusValue.join(',')
                }
                const selectRes = await productionPointInspectioWorkOrderList(param);
                this.djList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;
            },
            queryWDJ() {
                this.currentPage = 1;
                this.repairPointInspectioWorkOrderList();
            },
            handleCurrentChangeDJ(val) {
                this.repairPointInspectioWorkOrderList(); //查询table数据的方法
            },
            // 点检--结束


            // 保养--开始
            async repairMaintainExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionMaintainExecutorSelect(param);
                this.maintainPeople = selectRes.data;
                // this.maintainPeopleValue = selectRes.data[0].value;

                // this.repairMaintainWorkOrderExecutorSelect();
            },
            async repairMaintainWorkOrderExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionMaintainWorkOrderExecutorSelect(param);
                this.maintainStatus = selectRes.data;
                // this.maintainStatusValue = selectRes.data[0].value;
                // this.repairMaintainWorkOrderList();
            },
            async repairMaintainWorkOrderList() {
                var param = {
                    permission: this.permission,
                    // startDate: this.tools.formatDate(this.maintainTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    // endDate: this.maintainTime[1],
                    startDate: this.statisticalDataTime[0],
                    endDate: this.statisticalDataTime[1],
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                if (this.maintainPeopleValue.length != 0) {
                    param.execPersonStr = this.maintainPeopleValue.join(',')
                }
                if (this.maintainStatusValue.length != 0) {
                    param.orderStatus = this.maintainStatusValue.join(',')
                }
                const selectRes = await productionMaintainWorkOrderList(param);
                this.maintainList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;

            },
            queryMaintain() {
                this.currentPage = 1;
                this.repairMaintainWorkOrderList();
            },
            handleCurrentChangeMaintain() {
                this.repairMaintainWorkOrderList();
            },


            // 维修--开始
            async repairRepairExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionRepairExecutorSelect(param);
                this.repairPeople = selectRes.data;
                // this.repairPeopleValue = selectRes.data[0].value;
                // this.repairRepairWorkOrderExecutorSelect();
            },
            async repairRepairWorkOrderExecutorSelect() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await productionRepairWorkOrderExecutorSelect(param);
                this.repairStatus = selectRes.data;
                // this.repairStatusValue = selectRes.data[0].value;
                // this.repairRepairWorkOrderList();
            },
            async repairRepairWorkOrderList() {
                var param = {
                    permission: this.permission,
                    // startDate: this.tools.formatDate(this.repairTime[0], 'yyyy-MM-ddThh:mm:ss'), //时间传值格式更改
                    // endDate: this.repairTime[1],
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    startDate: this.statisticalDataTime[0],
                    endDate: this.statisticalDataTime[1],
                }
                if (this.repairPeopleValue.length != 0) {
                    param.execPersonStr = this.repairPeopleValue.join(',')
                }
                if (this.repairStatusValue.length != 0) {
                    param.orderStatus = this.repairStatusValue.join(',')
                }
                const selectRes = await productionRepairWorkOrderList(param);
                this.repairList = selectRes.data.rows;
                this.pageQuantity = selectRes.data.pageQuantity;
            },
            queryRepair() {
                this.currentPage = 1;
                this.repairRepairWorkOrderList();
            },
            handleCurrentChangeRepair() {
                this.repairRepairWorkOrderList(); //查询table数据的方法
            },


            // repairTime

            // 维修--结束



            handleClick(tab) {
                this.currentPage = 1;
                console.log(tab.index);
                if (tab.index == 0) { //待办
                    this.productionWaitDoneCount();
                    this.productionDoneExecutorSelect();
                    this.repairWaitDoneTableSelect();
                } else if (tab.index == 1) { //点检
                    this.repairPointInspectioExecutorSelect();
                    this.repairPointInspectioWorkOrderExecutorSelect();
                    this.repairPointInspectioWorkOrderList();
                } else if (tab.index == 2) { //保养
                    this.repairMaintainExecutorSelect();
                    this.repairMaintainWorkOrderExecutorSelect();
                    this.repairMaintainWorkOrderList();
                } else if (tab.index == 3) { //维修
                    this.repairRepairExecutorSelect();
                    this.repairRepairWorkOrderExecutorSelect();
                    this.repairRepairWorkOrderList();
                }
            },
            lookResult(row) {
                console.log(row)
                this.orderRecordList = [];
                this.orderRecordList = row.orderRecordList;
                this.dialogTableVisibleResult = true;
            },


            //接口-结束
            drawShape() {
                // 当天计划-开始
                this.productionCompletionRate1 = echarts.init(document.getElementById('productionCompletionRate1'));
                this.productionCompletionRate1.setOption({
                    series: [{
                        type: 'gauge',
                        startAngle: 215, //表盘开始角度
                        endAngle: -35, //表盘结束角度
                        center: ['50%', '60%'],
                        radius: '90%', //设置仪表盘的大小
                        min: 0,
                        max: 1,
                        splitNumber: 8,
                        axisLine: {
                            lineStyle: { //表盘设置
                                width: 22,
                                color: [
                                    [0.25, '#6395F9'],
                                    [0.5, '#28D793'],
                                    [0.75, '#657798'],
                                    [1, '#F6BD16']
                                ]
                            }
                        },
                        pointer: {
                            // icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                            length: '70%',
                            width: 10,
                            offsetCenter: [0, 0],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: { //表盘小刻度设置
                            length: 0,
                            lineStyle: {
                                color: 'auto',
                                width: 2
                            }
                        },
                        splitLine: { //表盘大刻度设置
                            length: 0,
                            lineStyle: {
                                color: 'auto',
                                width: 5
                            }
                        },
                        axisLabel: { //表盘文字
                            color: '#808080',
                            fontSize: 14,
                            distance: -30, //文字距表盘的距离
                            formatter: function (value) {
                                if (value === 0.875) {
                                    return '优';
                                } else if (value === 0.625) {
                                    return '良';
                                } else if (value === 0.375) {
                                    return '中';
                                } else if (value === 0.125) {
                                    return '差';
                                }
                            }
                        },
                        title: { //表盘文字
                            offsetCenter: [0, '30%'],
                            fontSize: 14
                        },
                        detail: {
                            fontSize: 24,
                            offsetCenter: [0, '60%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return Math.round(value * 100);
                            },
                            color: 'auto'
                        },
                        data: [{
                            value: this.yesterdayOutput.value,
                            name: '生产完成率',
                            // fontSize:20
                        }]
                    }]
                });
                // 前天计划-结束

                // 当天计划-开始
                this.productionCompletionRate2 = echarts.init(document.getElementById('productionCompletionRate2'));
                this.productionCompletionRate2.setOption({
                    series: [{
                        type: 'gauge',
                        startAngle: 215, //表盘开始角度
                        endAngle: -35, //表盘结束角度
                        center: ['50%', '60%'],
                        radius: '90%', //设置仪表盘的大小
                        min: 0,
                        max: 1,
                        splitNumber: 8,
                        axisLine: {
                            lineStyle: { //表盘设置
                                width: 22,
                                color: [
                                    [0.25, '#6395F9'],
                                    [0.5, '#28D793'],
                                    [0.75, '#657798'],
                                    [1, '#F6BD16']
                                ]
                            }
                        },
                        pointer: {
                            // icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                            length: '70%',
                            width: 10,
                            offsetCenter: [0, 0],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: { //表盘小刻度设置
                            length: 0,
                            lineStyle: {
                                color: 'auto',
                                width: 2
                            }
                        },
                        splitLine: { //表盘大刻度设置
                            length: 0,
                            lineStyle: {
                                color: 'auto',
                                width: 5
                            }
                        },
                        axisLabel: { //表盘文字
                            color: '#808080',
                            fontSize: 14,
                            distance: -30, //文字距表盘的距离
                            formatter: function (value) {
                                if (value === 0.875) {
                                    return '优';
                                } else if (value === 0.625) {
                                    return '良';
                                } else if (value === 0.375) {
                                    return '中';
                                } else if (value === 0.125) {
                                    return '差';
                                }
                            }
                        },
                        title: { //表盘文字
                            offsetCenter: [0, '30%'],
                            fontSize: 14
                        },
                        detail: {
                            fontSize: 24,
                            offsetCenter: [0, '60%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return Math.round(value * 100);
                            },
                            color: 'auto'
                        },
                        data: [{
                            value: this.todayOutput.value,
                            name: '生产完成率',
                            // fontSize:20
                        }]
                    }]

                });
                // 当天计划-结束
                // 设备KPI统计--开始
                // 设备KPI统计-OEE
                this.equiKPIStatisticsOEE = echarts.init(document.getElementById('equiKPIStatisticsOEE'));
                this.equiKPIStatisticsOEE.setOption({
                    color: ['#6395F9'],
                    series: [{
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: -180,
                        // center: ['50%', '50%'],
                        radius: '68%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#464646'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 18, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: this.kpiStatisticList.OEEValue,
                            name: 'OEE', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '-15%']
                            },
                            detail: {
                                offsetCenter: ['0%', '15%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#808080'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 14,
                            color: '#808080', //百分比的演的
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 1, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                });
                //设备KPI统计-利用率
                this.equiKPIStatisticsUtilizationRate = echarts.init(document.getElementById(
                    'equiKPIStatisticsUtilizationRate'));
                this.equiKPIStatisticsUtilizationRate.setOption({
                    color: ['#6395F9'],
                    series: [{
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: -180,
                        // center: ['50%', '50%'],
                        radius: '68%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#464646'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 18, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: this.kpiStatisticList.PowerOnUtilizationValue,
                            name: '利用率', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '-15%']
                            },
                            detail: {
                                offsetCenter: ['0%', '15%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#808080'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 14,
                            color: '#808080', //百分比的演的
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 1, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                });
                //设备KPI统计-开机率
                this.equiKPIStatisticsPowerOnRate = echarts.init(document.getElementById(
                    'equiKPIStatisticsPowerOnRate'));
                this.equiKPIStatisticsPowerOnRate.setOption({
                    color: ['#6395F9'],
                    series: [{
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: -180,
                        // center: ['50%', '50%'],
                        radius: '68%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#464646'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 18, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: this.kpiStatisticList.PowerOnRateValue,
                            name: '开机率', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '-15%']
                            },
                            detail: {
                                offsetCenter: ['0%', '15%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#808080'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 14,
                            color: '#808080', //百分比的演的
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 1, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                });
                //设备KPI统计-利用率-popover1
                this.equiKPIStatisticsUtilizationRatePopover1 = echarts.init(document.getElementById(
                    'equiKPIStatisticsUtilizationRatePopover1'));
                this.equiKPIStatisticsUtilizationRatePopover1.setOption({
                    color: ['#F6BD16'],
                    series: [{
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: -180,
                        // center: ['50%', '50%'],
                        radius: '90%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#464646'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: 10,
                            name: 'XXXX', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '-20%']
                            },
                            detail: {
                                offsetCenter: ['0%', '25%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#808080'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 14,
                            color: '#808080', //百分比的演的
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 1, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                });
                //设备KPI统计-利用率-popover2
                this.equiKPIStatisticsUtilizationRatePopover2 = echarts.init(document.getElementById(
                    'equiKPIStatisticsUtilizationRatePopover2'));
                this.equiKPIStatisticsUtilizationRatePopover2.setOption({
                    color: ['#62DAAB'],
                    series: [{
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: -180,
                        // center: ['50%', '50%'],
                        radius: '90%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#464646'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: 20,
                            name: 'XXXX', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '-20%']
                            },
                            detail: {
                                offsetCenter: ['0%', '25%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#808080'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 14,
                            color: '#808080', //百分比的演的
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 1, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                });
                //设备KPI统计-利用率-popover3
                this.equiKPIStatisticsUtilizationRatePopover3 = echarts.init(document.getElementById(
                    'equiKPIStatisticsUtilizationRatePopover3'));
                this.equiKPIStatisticsUtilizationRatePopover3.setOption({
                    color: ['#657798'],
                    series: [{
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: -180,
                        // center: ['50%', '50%'],
                        radius: '90%', //设置仪表盘的大小
                        pointer: { //仪表盘指针
                            show: false
                        },
                        progress: { //展示当前进度
                            show: true,
                            overlap: false, //多组数据时进度条是否重叠
                            roundCap: false, //显示进度是否有弧度
                            clip: false,
                            itemStyle: { //盘中进度样式
                                borderWidth: 0, //进度border-width
                                borderColor: '#464646'
                            }
                        },
                        axisLine: { //圆环的样式
                            lineStyle: {
                                width: 12, //宽度
                            }
                        },
                        splitLine: { //圆环上的大刻度
                            show: false,
                            distance: 0,
                            length: 10
                        },
                        axisTick: { //圆环上的小刻度
                            show: false
                        },
                        axisLabel: { //大的刻度值
                            show: false,
                            distance: 50
                        },
                        data: [{
                            value: 20,
                            name: 'XXXX', //title
                            title: { //仪表盘标题
                                offsetCenter: ['0%', '-20%']
                            },
                            detail: {
                                offsetCenter: ['0%', '25%']
                            }
                        }],
                        title: { //title样式
                            fontSize: 14,
                            color: '#808080'
                        },
                        detail: { //仪表详情，用于显示数据
                            width: 20,
                            height: 10,
                            fontSize: 14,
                            color: '#808080', //百分比的演的
                            borderColor: '#FFFFFF', //显示的数值，boder颜色
                            borderRadius: 20, //borer的弧度
                            borderWidth: 1, //border宽度
                            formatter: '{value}%'
                        }
                    }]
                });
                // 结束

                // 近15天设备KPI分析--开始
                // // // 近15天设备KPI分析--结束

                // XX工段生产数据对比--开始
                // // XX工段生产数据对比--结束

            },
            draw15KPIShape(data) {
                 echarts.init(document.getElementById('rentDaysEquiKPIStatistics')).dispose(); //销毁echarts
                this.rentDaysEquiKPIStatistics = echarts.init(document.getElementById('rentDaysEquiKPIStatistics'));
                this.rentDaysEquiKPIStatistics.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '2%',
                        textStyle: {
                            color: '#808080',
                            fontSize: 10,

                        }
                    },
                    grid: {
                        left: '4%',
                        right: '6%',
                        bottom: '10%',
                        top: "10%",
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#869da7',
                            fontSize: 10,
                            rotate: 40,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        nameRotate: '0.1',
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: ['#E3E4E6']
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#869da7',
                            fontSize: 12,
                            //  rotate:40  
                        }
                    },
                    series: data.series
                });
            },
            // 画工段生产数据对比图
            drawProductDataShape(data) {
                 echarts.init(document.getElementById('sectionProductionDataCompare')).dispose(); //销毁echarts
                this.sectionProductionDataCompare = echarts.init(document.getElementById(
                    'sectionProductionDataCompare'));
                this.sectionProductionDataCompare.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: data.legend,
                        bottom: '2%',
                        textStyle: {
                            color: '#808080',
                            fontSize: 10,
                        }
                    },
                    // color: ['#6395F9', '#62DAAB', '#657798'],
                    grid: {
                        left: '4%',
                        right: '6%',
                        bottom: '10%',
                        top: "10%",
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: data.xAxis.data,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#869da7',
                            fontSize: 10,
                            rotate: 40,//倾斜
                        },
                    },
                    yAxis: {
                        type: 'value',
                        nameRotate: '0.1',
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: ['#E3E4E6']
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#869da7',
                            }
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#869da7',
                            fontSize: 12,
                            //  rotate:40  
                        }
                    },
                    series: data.series
                })
            },
            //时间格式转化显示
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style>
    /*修改popper-class格式 popper-class .el-popover.my-popover 这种格式，前缀是.el-popover 不然不生效。且不能加上scoped */
    .el-popover.my-popover {
        top: 425px !important;
    }
</style>
<style scoped>
    .diy_col {
        height: 63vh;
        margin-bottom: 14px;
    }

    .diy_col1 {
        height: 40vh;
        margin-bottom: 14px;
    }

    .bg-purple {
        background: #E2F0FE;
        border: 1px solid #CCDBE5;
        height: 32px;
        line-height: 32px;
        display: flex;
        /* align-items: center; */
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;
    }

    .height_vh {
        height: 280px;
    }

    .height_vh1 {
        height: 280px;
    }

    .height_vh2 {
        height: 345px;
    }

    .el-row {
        margin-right: 0px !important;
    }

    .height_380 {
        height: 380px;
    }

    .datePickerWidth {
        width: 325px;
    }

    .dateTimeWidth {
        width: 140px;
    }

    .collapseStyle {
        border: 0px;
        height: 410px;
        /* height: 398px; */
        /* overflow-y: auto; */
    }

    .collapseStyle1 {
        border: 0px;
        height: 450px;
    }

     .dialog__body {
        height: 600px;
    }

    /* .diy_div {
        width: 110px;
        height: 110px;
    } */
</style>
<style lang="less" scoped>
    ::v-deep .el-input {
        /* width: 146px !important; */
    }

    ::v-deep .el-input__inner {
        height: 36px;
        line-height: 36px;
    }

    /* ::v-deep .el-input--mini .el-input__inner {
        height: 36px;
        line-height: 36px;
    } */

    ::v-deep .el-tabs__item {
        width: 80px;
        /* color: #999999; */
        padding: 0;
        font-size: 16px;
        height: 54px;
        line-height: 54px;
    }

    ::v-deep .el-tabs__item.is-active {
        color: #0099FF;
        font-weight: 500;
    }

    ::v-deep .el-badge__content.is-fixed {
        top: 15px;
        right: 6px;
    }

    ::v-deep .el-date-editor .el-range-separator {
        width: 20px;
    }

    ::v-deep .el-range-editor.el-input__inner {
        padding: 2px;
    }

    ::v-deep .el-date-editor .el-range-input {
        width: 50%;
    }

    ::v-deep .el-pagination {
        padding: 5px;
    }
</style>